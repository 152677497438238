.not-qualified-component {
  background-color: var(--secondary-orange);
  border-radius: var(--default-radius);
  display: flex;
  height: 30px;
  padding: 0 8px;

  &__icon {
    color: #FEE9E6;
    width: 13px;
  }

  &__text {
    color: var(--white, #FFF);
    font-size: 13px;;
  }
}
