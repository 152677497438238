.CgmGoalCardComponent {  
  .meeting-status {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-size: 12px;
      color: #c4c2c2;
    }
  }

  .title-content-text-title {
    font-size: 12px;
    margin-bottom: 3px;
    color: #6B7178
  }
  
  .tooltip-component.ant-space {
    gap: 2px !important;
  }
}
