.upload-file-list {
  .upload-file-list__item {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    border: 1px solid var(--border-gray);
    border-radius: 4px;
    color: var(--uc-blue);
  }
}

.upload-file-list__preview-modal {

  .ant-modal-body {
    display: flex;
  }

  img {
    width: 100%;
    height: 100%;
  }
}