.layout-sider {
  background-color: #f8f8f8;
  height: 100%;
}

.ant-layout-sider-collapsed {
  
  .ant-layout-sider-children,
  ul.ant-menu-inline-collapsed {
    display: none;
  }
}

.ant-layout-sider-trigger {
  width: 50px !important;
  position: absolute;
  right: -24px;
  bottom: 20px;
  background: transparent;

  .anticon {
    color: gray;
  }
}

.layout-sider-collapse-btn,
.layout-sider-collapse-btn:focus,
.layout-sider-collapse-btn:hover {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.sider-component-primary {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  border-right: 1px solid var(--gray-scale-4);

  .sider-header,
  .sider-content,
  .sider-footer {
    padding: 0 24px;
  }

  .fixedChildComponentWrapper {
    margin: 0;
  }

  .layout-sider {
    background-color: #fbfbfb;
  }

  .fixedChildComponent.scrollable {
    overflow-y: hidden;
  }
}
