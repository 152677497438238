.order-device-supplies-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.order-submit-popconfirm {
  .ant-popover-inner-content {
    width: 421px;
    padding: 32px;
  }

  .ant-popover-buttons {
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    margin: 15px 0px;

    button {
      margin-left: 0px;
    }
  }
}