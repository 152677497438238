.alert-list-component {
  color: var(--gray-scale-1);

  button {
    padding: 0;
    color: var(--gray-scale-1);

    &:hover {
      color: var(--primary-blue-6);
    }
  }
}
