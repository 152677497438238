.ant-tooltip.measurement-average-tooltip {
  max-width: none;

  .ant-tooltip-content {
    background-color: #161616;
  }

  .ant-tooltip-inner {
    padding: 24px;
  }

  .ant-tooltip-inner * {
    color: white;
  }

  .ant-tooltip-content,
  .ant-tooltip-inner {
    border-radius: var(--secondary-radius);
  }

  .value-data {
    min-width: 120px;
  }

  .value-info {
    color: #B5B9BE !important;
    opacity: 0.7;
  }
}