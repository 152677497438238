.dot-dropdown-component {
  .ant-dropdown-menu {
    position: relative;
    margin-top: 8px;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
