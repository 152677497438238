.watched-indicator-component {

  .anticon-eye {
    color: var(--gray-scale-3);
  }
}

.popover-overlay {

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-content {
    position: relative;
    margin-top: -10px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.08);
  }

  .ant-popover-inner-content {
    width: max-content;
    max-width: 100%;
    padding: 14px 21px 18px 24px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.tooltip-overlay {

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-content {
    position: relative;
    margin-top: -10px;
    border: 1px solid var(--gray-scale-3);
    border-radius: 4px;
  }

  .ant-tooltip-inner {
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: var(--gray-scale-2);
    border-radius: 2px;
  }
}

