.edit-select-cover-range-drawer {
  .ant-drawer-title {
    color: var(--Grayscale-1, #2D3238);
    font-family: Noto Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .ant-drawer-header {
    height: 80px;
    flex: none;
    padding: 22px 40px;
  }

  .ant-drawer-body {
    padding-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
  }
}