.providerTableWrapper {
  width: 100%;
  margin: 32px 24px;
  overflow-y: auto;
  overflow-x: hidden;
}

.providerViewTables {
  aside.ant-layout-sider-collapsed {
    .ant-layout-sider-children {
      visibility: hidden;
    }
  }

  .tableSider {
    .sider-content {
      overflow: auto;
      padding: 0 calc(24px - 15px);
    }
  }
}
