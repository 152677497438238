.device-checkbox-component {
  border: 1px solid var(--border-gray);
  padding: 20px;
  margin-top: 12px;
  border-radius: var(--secondary-radius);

  &.has-expand {
    background-color: #FAFAFA;
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .first-section {
    width: 190px;
    margin-right: 20px;
  }


  .device-img {
    width: 100px;
    aspect-ratio: 1;
    object-fit: contain;
    margin-right: 30px;
  }

  .device-info {
    align-self: center;
  }

  .expand-section-icon {
    flex-grow: 1;
    align-self: center;
    margin-left: 340px;
    cursor: pointer;
  }
}
