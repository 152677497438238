.ai_instruction {
  font-size: 12px;

  white-space: pre-wrap;
  word-wrap: break-word;

  max-height: 700px;
  max-width: 700px;

  overflow: auto;

  p {
    font-weight: 700;
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 10px;
    margin: 10px;
    overflow: none !important;
    /* Optional: Add a border for distinction */
    border: 1px solid #ccc;
    /* Optional: Add a background color */
    background-color: #f9f9f9;
  }
}
