.no-children {
  margin-bottom: 10px;

  .ant-form-item-control {
    display: none;
  }
}

.no-style-label.ant-form-item {
  .ant-form-item-label > label {
    &::before, &::after {
      display: none;
    }

    color: unset;
    font-size: unset;
  }
}
