.call-center-status-btn {
  &.checking-login {
    .solid-dot {
      display: none;
    }

    .tooltip-component {
      gap: 0px !important;
      width: 18px;
    }
  }

  &.tag:not(.bg-none) {
    width: 120px;
    height: 32px;
    border: 1px solid var(--light-gray);
    border-radius: 16px;
    color: var(--gray-scale-2) !important;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    padding: 0px !important;


    & svg {
      font-size: 12px;
    }
  }

  .solid-dot {
    margin-left: 3px;
    margin-right: 8px !important;
  }
}