.call-center-status-dropdown {
  .ant-dropdown-trigger {
    display: flex;
    flex-direction: column;

    &:after {
      border-radius: 4px;
    }
  }

  .call-center-agent-status-indicator.logged-in {
    width: 100%;
    margin-top: 4px;
    border-bottom: solid 4px;
    position: absolute;
    bottom: -8px;

    &.Online {
      border-color: var(--secondary-green);
    }

    &.Offline {
      border-color: var(--secondary-gray);
    }

    &.Busy {
      border-color: var(--secondary-yellow);
    }

    &.Error {
      border-color: var(--secondary-red);
    }
  }

  &:hover {
    .call-center-agent-status-indicator {
      color: unset;
      opacity: 0.8;
    }
  }

  .tag-text {
    font-size: 14px;

    .ant-space-item {
      color: var(--gray-scale-2);
    }
  }
}

.ant-dropdown.call-center-status-dropdown-overlay {
  width: 160px;

  .ant-dropdown-menu-item-group-title[title="AWS status"] {
    font-size: 14px;
    margin-top: 3px;
  }

  .ant-dropdown-menu-item-only-child {
    padding: 8px 12px;
  }
}
