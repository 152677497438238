.qualified-component {
  background-color: var(--primary-blue-6);
  flex-direction: row;
  border-radius: var(--default-radius);
  display: flex;
  height: 30px;
  padding: 0 8px;

  &__icon {
    color: #E6F7FF;
    width: 13px;
  }

  &__text {
    color: var(--white, #FFF);
    font-size: 13px;
  }
}
