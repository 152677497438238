@use './zIndex.scss';

.tab-fixed-header {
  position: relative;
  overflow: hidden;
  height: 100%;

  .ant-tabs-tab {
    padding-top: 0;
  }

  .ant-tabs-nav {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: zIndex.$ant-tabs-nav;
  }

  .ant-tabs-content-holder {
    margin-top: 60px;
    overflow: auto;
    height: 100%;
  }

  .ant-tabs-tabpane, .ant-tabs-content {
    height: 100%;
  }
}
