.feedback-btn-component {
  button {
    border-color: var(--light-gray) !important;
    border-radius: 6px;
    padding-left: 10px; 
    padding-right: 10px; 

    * {
      color: var(--gray-scale-2);
    }
  }

  .base-icon-container {
    display: flex;
    justify-content: center;
  }
}
