.intervention-goal-form-component {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0px 0px;
  gap: 20px;
}

.goal-form-description {
  width: 55%;
  display: flex;
  .ant-form-item-row {
    width: 100%;
  }
}

.goal-form-meeting-status {
  width: 245px;
}

.intervention-goal-type {
  .ant-form-item {
    margin-bottom: -14px;
    .ant-form-item {
      margin-bottom: 0px;
    }
  }

  .ant-form-item-control-input-content {
    height: 100%;
  }
}

.goal-description-input{
  display: block;
  margin-top: 4px;
}

.goal-form-date-and-deleteIcon {
  width: 100px;
  display: flex;
  .anticon {
    color: gray;
  }
}

.clinical-goal-list {
  display: flex;
  flex-direction: column;
}

#clinicalGoalForm {
  .require-clinical-goal-placeholder .ant-form-item-control-input {
    height: 0px;
    min-height: 10px;
  }
}
