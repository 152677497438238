.medication-reminder-list__form {
  gap: 10px 20px;
}

.medication-reminder__item {
  flex-basis: 45%;

  .ant-form-item-row {
    flex-direction: column;

    .ant-form-item-label {
      text-align: left;
    }
  }
}