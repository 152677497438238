.introducing-new-payment-popup {
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0px 0px;
  }

  .ant-modal-body {
    padding: 24px 40px;
    font-size: 16px;
  }

  .render-image {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .render-title {
    width: 75%;
  }
}
