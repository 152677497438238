body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn.ant-btn-primary {
  background-color: var(--uc-blue);
}

.ant-btn.ant-btn-primary.ant-btn-dangerous {
  background-color: var(--secondary-red);
  border-color: var(--secondary-red);
}

.strict-mode-off-note {
  position: fixed;
  top: 0;
  left: 0;
  color: #CB1C1C; /* dark-red */
  background-color: #FFF;
  z-index: 9999;
  font-size: 16px;
  font-weight: 600;
  padding: 14px;
  width: 250px;
}