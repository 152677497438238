.sticky-note-component {
  position: relative;
  width: 230px;
  padding-top: 23px;
  padding-bottom: 5px;
  background: #FEF4D6;
  overflow: hidden;
  max-height: 50px;
  transition: max-height 0.3s ease-out;

  &.not-expanded {
    background: linear-gradient(to left bottom, transparent 50%, #D9D9D9 0) no-repeat 100% 0 / 30px 20px,
      linear-gradient(-146deg, transparent 17px, #FEF4D6 0);
    filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
  }

  &.expanded {
    max-height: 560px;
    padding-top: 10px;
    padding-bottom: 15px;
    width: 404px;
    transition: max-height 0.2s ease-in;
  }

  &__content {
    padding: 0 10px;
    max-height: 20px;
    transition: max-height 0.2s ease-in;

    .ant-typography {
      // 493px because the max height for the entire note
      // should be 560 and because the info at the bottom,
      // paddings take 25px + 42px for buttons then this should be 493px
      max-height: 493px;
      margin-bottom: 0;
    }

    .ant-typography {
      textarea, textarea:focus, textarea:active {
        border: unset;
        outline: unset;
        background-color: transparent;
        padding: 0;
      }
    }

    &.no-overflow {
      .ant-typography {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    
    &.expanded {
      max-height: 100%;

      .ant-typography {
        white-space: normal;
        overflow: auto;
        text-overflow: clip;
      }
    }
  }

  &__add-btn {
    height: 22px;
    width: 22px;
  }

  .sticky-note-input-component {
    &__cancel-btn {
      background-color: transparent;
    }

    &__cancel-btn, &__submit-btn {
      color: #525252;
    }
  }

  button {
    color: var(--gray-scale-3);
  }

  .sticky-note-single-memo-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: pre-wrap;

    &__note {
      min-width: 250px;
    }
  }

  .sticky-note-metadata-component {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: var(--gray-scale-2);
  }

  &__closed-memo {
    display: flex;
    justify-content: space-between;
  }

  &__closed-memo-text {
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
