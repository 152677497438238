.outstanding-component {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .tab-fixed-header {
    & > .ant-tabs-content-holder {
      margin-top: 80px;
    }
  }
}
