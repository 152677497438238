.worklist-patient-info-component {
  background-color: white;
  padding: 15px;

  .label-value-component__value {
    color: var(--gray-scale-2);
  }

  .worklist-patient-info .label-value-component {
    flex-wrap: unset !important;
    align-items: flex-start;

    .label-value-component__value {
      display: initial;
    }
  }
}
