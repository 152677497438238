.insurance-card-list {
  .empty-insurance {
    align-items: baseline;
    border: 1px solid #E7EAED;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 15px;
    padding-bottom: 32px;
  }
}