.patient-page-visit-component {
  display: flex;
  justify-content: space-between;
  margin-right: 5%;
  .bg-none {
    padding: 0px;
  }
  .interactive-tag {
    cursor: pointer;
  }
}

.ongoing-duration-time {
  color: red;
}