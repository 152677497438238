.alert-card-component {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: unset;

  &.success {
    background-color: #E5F9F8;
  }

  &.warning {
    background-color: #FEE9E6;
    border-radius: 4px;
    padding: 14px;
    display: inline-block;

    &.warning-border {
      border: 1px solid #F5C9C3;
    }
  }

  &.error {
    background-color: #FEE9E6;
  }

  &.info {
    background-color: #FEF4D6;
    text-align: center;
    font-weight: 500;
  }

  .anticon {
    &.info {
      color: #F2C81A;
    }
  }

  &.center {
    justify-content: center;
    padding: 6px 26px;
  }

  &.left {
    justify-content: flex-start;
    padding: 6px 26px;
  }

  &.right {
    justify-content: flex-end;
    padding: 6px 26px;
  }

  &.padding-small {
    padding: 6px 20px
  }
}
