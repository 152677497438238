$button-width: 46px;
.waystar-provider-select {
  max-width: calc(100% - $button-width);
  .ant-select-selector {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

.waystar-provider-select__input-type-btn {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background: #FFFFFF;
  width: $button-width;

  &:hover {
    background: #FFFFFF;
  }
}

.waystar-provider-select__option {
  &.waystar-provider-select__option-selected {
    font-weight: 600;
  }
}