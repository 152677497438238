.expiration-status {
  font-weight: 500;

  .expiring,
  .expired {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 3px;
    align-items: center;

  }

  .expiring,
  .expiring * {
    color: var(--secondary-red);
  }

  .expired,
  .expired svg {
    color: var(--gray-scale-2);
  }


  .base-svg-icon {
    margin-top: -2px;
  }
}