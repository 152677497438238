.patient-search-select-component {
  width: 100%;

  .ant-select-selector {
    border-radius: var(--secondary-radius) !important;
  }
}

.patient-select-popup {
  .ant-select-item-option {
    background: #FFFFFF;
  }

  .ant-select-item-option-disabled:first-of-type {
    cursor: default;
  }

  .ant-select-item-option-disabled {
    position: unset;
  }

  .patient-select-loading {
    width: 95%;
    height: 75%;
    position: absolute;
    top: 0;
    left: 0;

    .ant-spin {
      margin-top: -50px;
    }
  }
}