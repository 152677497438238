.enrollment-comp {
  height: 100%;

  .ant-steps {
    width: 650px;
  }

  .vitals-monitoring-card {
    padding: 20px;
  }
}
