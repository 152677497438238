.mtpr-form {
  --mtpr-border-style: 1px solid var(--border-gray);
  height: 100%;
  .clinicalGoals {
    padding-bottom: 18px;

    &:not(:first-of-type) {
      padding-top: 18px;
    }

    border-bottom: var(--mtpr-border-style);
  }

  .goal-form-status {
    justify-content: space-between;
  }

  .clinic-goals-form-buttons {
    padding-top: 18px;
  }
}