.ant-form-item {
  .ant-form-item-label > label {
    color: var(--secondary-gray);
    font-size: 14px;
  }

  .ant-col.ant-form-item-label {
    padding-bottom: 4px;
  }
}
