.ControlLevelCardComponent{
  &__background {
    background-color: #f6f6f6;
    border-radius: 8px;
    margin: 20px 20px 20px 20px;
    padding: 10px 20px 10px 20px;
  } 

  &__measurement-box {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 340px;
    height: 70px;
  }

  .title-style {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }

  .name-style {
    font-size: 12px;
    font-weight: 400;
    width: 90px;
  }

  .value-style {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
}

.component-in-worklist {
  &__background {
    background-color: #f6f6f6;
    border-radius: 8px;
    padding: 10px 20px 10px 20px;
  }
}
