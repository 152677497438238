.select-cover-range-form-component {
  height: 100%;

  .fixedChildComponentWrapper {
    margin: 0 0;
  }

  .confirm-footer {
    height: 140px;
    padding-top: 30px;
  }
}

.render-select-member-to-cover {
  margin-top: 29px;
  margin-bottom: 29px;

  .render-title {
    color: var(--Grayscale-1, #2D3238);
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .render-text {
    color: var(--Grayscale-2, #6B7178);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.render-card-body {
  border-radius: 8px;
  border: 1px solid var(--Grayscale-4, #E7EAED);
}