.RequiredAsteriskComponent {
  &.showBefore::before {
    display: inline;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*";
  }

  &.showAfter::after {
    display: inline;
    margin-inline-end: 4px;
    margin-left: 3px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*";
  }

  &.small {
    &::after, &::before {
      font-size: 14px;
    }
  }

  &.medium {
    &::after, &::before {
      font-size: 16px;
    }
  }

  &.large {
    &::after, &::before {
      font-size: 18px;
    }
  }
}
