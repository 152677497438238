.minimizable {
  transition: height 0.3s;

  .minimizable-control {
    .anticon.anticon-expand-alt {
      font-size: 1.5em;
    }
  }
}

.minimized {
  &.minimizable-children {
    height: 0px;
    opacity: 0;
    display: none;
  }

  &.minimizable-control {
    min-height: 1.5em;
  }
}