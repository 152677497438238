.summary-table {
  // min-width: 440px;

  .ant-table-thead {
    .ant-table-cell {
      padding: 4px 10px !important;
      line-height: 18px;
    }
  }

  .ant-table-body {
    .ant-table-cell {
      height: 42px !important;
    }
  }
}

.log-book-table {
  // min-width: 512px;
}

// scroll change the css from ant-table-container to ant-table-body
// use border and scroll={{ y: 44 * x }} in table
.vitals-bordered-table {
  // set header title color
  .ant-table-thead>tr>th {
    color: var(--secondary-gray) !important;
  }

  // make it round - table BODY
  // only for bordered table
  .ant-table.ant-table-bordered>.ant-table-container {
    border-radius: var(--small-table-radius);
    border: 1px solid #B5B9BE;
  }

  // make it round - thead
  .ant-table-header {
    border-top-right-radius: var(--small-table-radius);
    border-top-left-radius: var(--small-table-radius);

    border-bottom: 1px solid #B5B9BE;

    // add border to the left except the first th
    tr>th:not(:first-child) {
      border-left: none;
    }

    // if using rowSpan, border the other rows
    tr:not(:first-child)>th {
      border-left: 1px solid #B5B9BE;
    }
  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th {
    border-bottom: none;
  }

  // table BODY - add border to the left except the first th
  // only for bordered table
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td:not(:first-child) {
    border-left: 1px solid #B5B9BE;
  }
}

.vitals-bordered-table-monitoring {
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td:first-child {
    color: var(--secondary-gray);
    font-weight: 500;
  }
}

.vitals-un-bordered-table {

  // set header title color
  .ant-table-thead>tr>th {
    color: var(--secondary-gray) !important;
  }

  .ant-table-thead>tr>th {
    background-color: white;
  }

  // color alternating rows
  // light gray background for the odd rows
  // white for the even ones
  tr:nth-child(even) {
    background: #F4F6F8
  }

  .ant-table-tbody>tr>td {
    border-bottom: none;
  }

  .ant-table-thead tr th {
    border-bottom: 1px solid #B5B9BE;
  }

  .ant-table-tbody>tr:nth-child(5)>td {
    border-bottom: 1px solid #B5B9BE;
  }
}
