.resizable {
  position: relative;
  height: auto;
  max-height: 100vh;
}

.resizable .resizable-handle {
  --resize-handle-width: 14px;
  --resize-handle-height: 14px;
  position: absolute;
  width: var(--resize-handle-width);
  height: var(--resize-handle-height);
  user-select: none;
  pointer-events: auto;

  &.resize-handle-n {
    width: calc(100% - var(--resize-handle-width));
    cursor: ns-resize;
    top: calc(-1 * var(--resize-handle-height) / 2);
    left: calc(var(--resize-handle-width) / 2);
  }

  &.resize-handle-ne {
    cursor: nesw-resize;
    top: calc(-1 * (var(--resize-handle-height) / 2));
    right: calc(-1 * (var(--resize-handle-width) / 2))
  }

  &.resize-handle-e {
    height: calc(100% - var(--resize-handle-height));
    cursor: ew-resize;
    top: calc(var(--resize-handle-height) / 2);
    right: calc(-1 * (var(--resize-handle-width) / 2));
  }

  &.resize-handle-se {
    cursor: nwse-resize;
    bottom: calc(-1 * (var(--resize-handle-height) / 2));
    right: calc(-1 * (var(--resize-handle-width) / 2));
  }

  &.resize-handle-s {
    width: calc(100% - var(--resize-handle-width));
    cursor: ns-resize;
    bottom: calc(-1 * (var(--resize-handle-height) / 2));
    left: calc(var(--resize-handle-width) / 2);
  }

  &.resize-handle-sw {
    cursor: nesw-resize;
    bottom: calc(-1 * (var(--resize-handle-height) / 2));
    left: calc(-1 * (var(--resize-handle-width) / 2))
  }

  &.resize-handle-w {
    height: calc(100% - var(--resize-handle-height));
    cursor: ew-resize;
    top: calc(var(--resize-handle-height) / 2);
    left: calc(-1 * (var(--resize-handle-width) / 2));
  }

  &.resize-handle-nw {
    cursor: nesw-resize;
    bottom: calc(-1 * (var(--resize-handle-height) / 2));
    left: calc(-1 * (var(--resize-handle-width) / 2));
  }
}