.cgm-chart-wrapper {
  --cgm-selection-blue: #107EFF;
  padding: 0px !important;
  margin-top: 10px;

  .date-navigator {

    .date-navigator__prev,
    .date-navigator__next {
      padding: 4px 8px;
    }

    .date-navigator__prev:disabled,
    .date-navigator__next:disabled {
      border: unset;
      background-color: var(--gray-scale-8);
    }
  }
}