.behavior-goal-list {
  .behavior-goal-type {
    width: 150px;
    display: flex;
    flex-wrap: wrap;
  }

  .behavior-goal-items {
    display: flex;
    gap: 5px;
    flex-direction: column;
    flex: 1;
    padding-right: 12px;
  }

  .intervention-goal-content .goal-description {
    width: 70%;
  }
}