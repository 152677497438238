.follow-up-schedule-with-existing-clinic-events {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0px;

  .ant-divider.section-divider {
    border-width: 3px;
    margin: 0px;
  }

  .follow-up-schedule-button {
    margin-top: 6px;
  }
}