.logged-in-user-dropdown {
  min-width: 300px !important;

  li:not(.ant-dropdown-menu-item-divider) {
    padding: 0px;
    font-weight: 500;
    height: 65px;
  }

  li.ant-dropdown-menu-item-group {
    height: auto;
  }

  div:not(.onsite-info)>.base-icon-container {
    margin-left: 4px;
    margin-right: 5px;
    width: 25px;
    height: 23px;
  }

  .ant-dropdown-menu-title-content>*,
  .ant-dropdown-menu-item-group>.ant-dropdown-menu-item-group-title {
    padding: 10px 18px 10px 12px;
    align-items: center;
    height: 100%;
  }

  .ant-dropdown-menu-item-group-title {
    font-size: 12px;
    margin-left: 4px; // compensate with icon margin left
  }

  .ant-dropdown-menu-item-group>.ant-dropdown-menu-item-group-list {
    margin-left: 47px;
  }

  .ant-dropdown-menu-item-group-list > li {
    height: 38px;
    margin-bottom: 12px;
  }
}