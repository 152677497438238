.medical-alert-reason-hidden {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.medical-alert-content {
  padding: 7px 16px;
}

.medical-alert-content-item {
  margin: 8px 0px;
}

.expand-collapse {
  color: gray;
  background-color: rgb(232, 232, 232);
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
}

.medical-alert-generated-time {
  color: #6B7178;
  padding-bottom: 3px;

  svg {
    width: 12px;
  }
}