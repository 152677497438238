.announcement-notification-container {
  padding: 0;
  .ant-notification-notice-message {
    padding: 16px 21px 8px;
  }
}

.announcement-button-badge {
  .ant-badge-dot {
    width: 9px;
    height: 9px;
    min-width: 9px;
  }
}
