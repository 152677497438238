.BillingTimeTableComponent {

  .ant-table-cell {
    min-width: 0;
    max-width: 100px;
  }

  .ant-card {
    background: transparent;
  }

  .ant-card-body {
    padding: 0;
  }

}
