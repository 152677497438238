.bg-logbook-table {
  .ant-table-header {
    .ant-table-cell {
      padding: 0px 10px !important;
      line-height: 22px;
    }
  }

  .ant-table-body {
    .ant-table-cell {
      height: 44px !important;
    }
  }

  //table width is 512px, Over-night will be break in 3 lines
  .ant-table-thead {
    tr:first-child>th:last-child {
      letter-spacing: -1px;
    }
  }
}
