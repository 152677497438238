.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid  var(--bg-light-gray);
  background-color: var(--bg-light-gray);
  border-radius: 2px;
}

.lab-result-detail-table-component {
  .ant-table-thead > tr > th {
    background-color: var(--light-gray);
    border-bottom: 1px solid var(--light-gray);
    border-top: 1px solid var(--light-gray);
    padding: 12px 18px;
    text-align: left;
  }

  .ant-table-footer {
    background-color: #FFFFFF;
    padding: 0px;

    .ant-btn {
      margin-top: 18px;
      height: 40px;
      background-color: var(--uc-blue-secondary);
      border-color: var(--uc-blue-secondary);
      color: var(--gray-scale-1);
    }
  }
}
