.print-care-plan-item {
  .print-care-plan-item-label,
  .print-care-plan-item-content,
  .print-care-plan-item-content > * {
    display: initial;
    color: var(--gray);
    font-size: 12px;
  }
  
    .print-care-plan-item-label {
      margin-right: 5px;
    }
}