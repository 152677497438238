.CareNoteCardComponent {
  .ant-card-body {
    padding: 16px 20px !important;
  }

  .card-header-component {
    padding-bottom: 10px;
  }

  div.ant-typography {
    margin-bottom: 0;
  }

  .ant-tag {
    white-space: normal;
  }

  .ant-card {
    white-space: pre-wrap;
  }
}
