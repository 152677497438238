.pageTitle {
  .page-title-tooltip {
    color: gray;
    font-size: 14px;
  }
}

.page-title-tooltip-overlay {
  max-width: 500px;
}
