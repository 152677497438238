.history-drawer-component-btn {
  padding: 0;
}

.see-history-btn {
  color: var(--gray-scale-2);

  span {
    text-decoration: underline;
  }
}
