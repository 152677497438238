.fixedComponent {
  height: 100%;
  display: flex;
  flex-direction: column;

  .fixedChildComponentWrapper {
    height: 100%;
    margin: 0 10px;
  }

  .fixed {

  }

  .scrollable {
    flex: 1;
    overflow-y: auto;
  }
}
