.device-component {
  position: relative;

  .order-drawer-button {
    position: sticky;
    top: 0px;
    right: 14px;
    width: 100%;
    background-color: #FFFFFF;
    padding-bottom: 5px;
    z-index: 3;
    margin-top: -32px !important; // to align with anchor target offset
  }
}
