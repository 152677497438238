.header-popover-component {
  //24px is ant-popover-inner-content padding: 12px 12px;
  width: 176px + 24px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-content {
    position: relative;
    margin-top: -10px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.08);
  }

  .ant-popover-inner-content {
    width: max-content;
    max-width: 100%;
    padding: 12px 12px;
    color: rgba(0, 0, 0, 0.85);
  }
}
