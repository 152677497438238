.health-condition-tooltip-overlay {
  .ant-tooltip-inner {
    width: 480px;
  }
}

.patient-info-card-component {
  .full-content-value-text {
    display: block !important;
    .breakword {
      white-space: nowrap;
      word-break: unset;
    }
  }

  .ellipse {
    .full-content-value-text {
      flex-wrap: nowrap;

      span.flex {
        display: inline;

        div {
          display: inline;
        }
      }
    }

  }
}
