.CareNoteCollapsibleHistoryComponent {
  .ant-collapse-header {
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 12px 0 !important;
    border-bottom: 1px solid #BFBFBF;
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      border-bottom: none;
    }
  }
}
