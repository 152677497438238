.message-vital-single-value {
  &__value-and-unit {
    flex-wrap: wrap;
    gap: 3px;
  }

  &__value {
    font-size: 16px;
    font-weight: 800;
  }
  
  &__unit {
    font-size: 12px;
  }
}