.outstanding-visit-card-items-component {
  display: flex;
  justify-content: space-between;
  margin-right: 5%;
  .bg-none {
    padding: 0px;
  }
  .interactive-tag {
    cursor: pointer;
  }
  .ant-btn-primary:not(:disabled) {
    background-color: #1890FF;
    border-color: #1890FF;
  }

  .ant-row {
    height: auto;
    margin-bottom: 20px;
  }
}
