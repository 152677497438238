.unenrolled-patient-info-card-component {
  padding: 20px;
  border-radius: var(--secondary-radius);
  background: #FFFFFF;

  .patient-name {
    font-size: 24px;
    line-height: 28px;
    color: var(--gray);
  }

  .row-pt15 {
    padding-top: 15px;
  }

  .ant-btn-primary {
    color: #fff;
    border-color: #1890FF;
    background: #1890FF;
    height: 40px;
    justify-content: center;
    align-items: center;
  }

  .ant-btn-default {
    color: #1890FF;
    border-color: #1890FF;
    background: #fff;
    height: 40px;
    justify-content: center;
    align-items: center;
  }

  .menu-dot-btn {
    border-color: #D9D9D9;
    background-color: #D9D9D9;
  }

  .ant-btn-ghost {
    color: #2D3238;
    border-color: #D9D9D9;
    background: #FAFAFA;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    height: 40px;
    justify-content: center;
    align-items: center;
  }

  .gap25 {
    gap: 25px;
  }

  .ant-card-body {
    padding: 0;
  }
}
