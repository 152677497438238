.pause-service-form-component {
  .pause-service-tool-tip {
    // margin-bottom: 28px;
  }
  
  .need-reschedule-visit {
    color: var(--secondary-red);
  }
  
  .fixedComponent .fixedChildComponentWrapper {
    margin: 0 !important;
  }

  &__cancel-btn {
    margin: 0;
  }
}
