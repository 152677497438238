.care-protocol {
  position: relative;

  .care-protocol-card {
    background: linear-gradient(180deg, #F6FAFF 0%, #EFF7FF 100%);
    border: 1px solid #DAF2FF;
    border-radius: 6px;
    padding: 16px;
    width: 100%;
    max-width: 836px;
  }

  .care-protocol-title {
    font-weight: 700;
    font-size: 16px;
    color: var(--gray-scale-1);
    margin: 0 0 8px 0;
  }

  .care-protocol-list {
    font-weight: 400;
    font-size: 14px;
    color:var(--gray-scale-1);
    margin: 0;
    padding-left: 20px;
  }

  .care-protocol-list li {
    margin-bottom: 3px;
  }

  .care-protocol-icon {
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: auto;
    transform: rotate(30eg);
    z-index: 0;
  }
}
