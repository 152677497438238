.message-popup-component {
  width: 440px;
  left: inherit;

  &__mark-btn {
    flex: 1
  }

  .ant-drawer-mask {
    display: none;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .render-custom-title {
    padding: 6px 18px;
  }

  .ant-drawer-header {
    padding: 0px 0px;
    border-color: #ddd;
    width: 100%;
    cursor: ns-resize;
  }

  .ant-drawer-close {
    margin-right: 0;
    margin-left: 16px;
    color: #333;
  }

  .ant-drawer-body {
    padding: 0px;
  }
}
