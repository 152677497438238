@use '../../../../styles/zIndex.scss';

.visit-info-component {
  .ant-typography {
    margin: 0;
  }
}

.visit-info-component__transcribe-drawer {
  z-index: calc(zIndex.$ant-modal + 1);
}
