.device-replacement-checkbox {
  .device-replacement-reason {
    flex-wrap: nowrap;
    gap: 10px;
  }

  .ellipsis-component {
    width: auto !important;
    max-width: 50%;
  }

  .device-replacement-extra-content {
    .ellipsis-component {
      width: auto !important;
      max-width: 80%;
    }
  }
}