.mtpr-see-note-history {
  .ant-modal-body {
    height: 593px;
  }

  &.ant-modal-wrap {
    pointer-events: none;
  }

  .ant-modal-content {
    pointer-events: auto;
  }
}