.CommentWAvatarComponent {
  .CommentAndDateWrapper {
    display: flex;
    justify-content: space-between;
    color: var(--text-gray-primary);
  }

  .Comment {
    word-wrap: break-word;
    word-break: break-word;
  }
}
