.print-care-plan-preview__medication-item {
  .print-care-plan-item {
    display: ruby;
  }

  .print-care-plan-preview__medication-item-content {
    gap: 0px 5px;
    margin-top: 3px;
    width: 100%;
  }

  .print-care-plan-preview__medication-item-content,
  .print-care-plan-preview__medication-item-content__item {
    display: initial;
  }
}