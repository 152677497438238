.cgm-chart-has-no-data {
  .highcharts-plot-band-label {
    margin-top: -18px !important;
  }

  .highcharts-plot-line-label {
    opacity: 0.3;
  }

  [class*="highcharts-plot-line"] {
    opacity: 0.6;
  }
}

.cgm-chart-day-view {
  padding-top: 18px;
  padding-bottom: 45px;
  background-color: rgba(217, 217, 217, 0.1);
  border-top: 1px solid #B5B9BE;
  border-bottom: 1px solid #B5B9BE;

  .highcharts-background {
    fill: transparent;
  }

  .cgm-chart-day-view__close-button {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    margin-bottom: 10px;
    margin-right: 8px;
  }
}

.cgm-chart-detail-show {
  .cgm-chart-selection {
    stroke: var(--cgm-selection-blue);
    stroke-width: 2px;
  }

  .cgm-chart-selection__tick {
    fill: var(--cgm-selection-blue) !important;
    font-weight: 600 !important;
  }
}

.cgm-chart-band {
  fill: transparent;

  &:hover {
    fill: var(--cgm-selection-blue);
    fill-opacity: 0.1;
    cursor: pointer;
  }

  &__selected {
    stroke: var(--cgm-selection-blue);
    stroke-width: 2px;
  }
}