.bubble-tab-component > .ant-tabs-nav {
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
  }

  &::before {
    border-color: #BFBFBF;
  }

  .ant-tabs-tab {
    background: var(--light-gray) !important;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 6px 14px;
    font-size: 14px;
    line-height: 22px;
    border-radius: 100px !important;
  }

  .ant-tabs-tab-active {
    background: var(--uc-blue-secondary);
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 10px;
  }
}
