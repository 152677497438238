.patientSearchSelectItemName {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray);
}

.patientSearchSelectItemGenderDob {
  font-size: 12px;
  font-weight: 400;
  color: #6B7178;
}
