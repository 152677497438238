.cgm-device-drawer {
  .ant-drawer-body {
    padding-right: 0px;
    padding-left: 0px;
  }

  .cgm-device-drawer__body,
  .cgm-device-form {
    height: 100%;
  }
}

.cgm-device-drawer__body {
  .ant-form-item-row {
    margin-bottom: 40px;
  }

  .ant-form-item-control {
    margin-top: 10px;
  }

  .cgm-device-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cgm-device-form-item,
  .cgm-device-form__buttons>div {
    padding-right: 24px;
    padding-left: 24px;
  }

  .cgm-account-linking-content__buttons:not(.cgm-linked) {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;

    button {
      width: 100%;
    }
  }

  .cgm-device-form__buttons {
    border-top: 1px solid var(--gray-scale-4);

    .form-submit-button-right button {
      width: 75px;
    }
  }
}