.rewrite-message-button {
  position: absolute;
  margin-top: 6px;
  margin-left: 6px;
}

.rewrite-note-button {
  margin-top: -45px;
  margin-right: 20px;
}

.rewrite-ai-button {
  width: 26px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.rewrite-ai-button:hover {
  box-shadow: 0px 4px 10px #1890ff;
}
