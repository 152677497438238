.consent-form-paper-form-item {
  display: flex;
  flex-direction: column;
  gap: 5px 0px;
  height: 100%;
  overflow: hidden;

  .consent-form-paper-form-item__pdf-preview {
    flex: 1;
  }

  label {
    font-size: 12px !important;
  }

  .ant-picker {
    width: 208px;
  }

  .ant-input {
    width: 402px;
    cursor: default;
  }
}

.consent-form-paper-form-item__upload-placeholder {
  .ant-form-item-control-input {
    height: 0px;
    min-height: 0px;
  }

  &.has-valid-file .ant-form-item-explain-error {
    display: none;
  }
}