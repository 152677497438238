.translated-label {
  border: 1px solid #e0e0e0;
  border-bottom: none;
  background-color: #F4F6F8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px;

  .translated-text-content {
    border-left: 2px solid #e0e0e0;
    margin-left: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
  }  
}

.translate-language-dropdown {
  max-height: 170px;
  overflow-y: auto;
}
