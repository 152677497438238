.ant-popover.chat-message-popover {
  .ant-popover-arrow {
    display: none !important;
  }

  .ant-popover-inner {
    height: 28px;
    padding: 4px 10px 8px !important;
    border-radius: 20px !important;
    margin-bottom: 150px;
  }

  .ant-popover-inner-content {
    padding: 0px !important;

    img {
      height: 20px;
      cursor: pointer;

      &.disabled {
        opacity: 0.1;
        cursor: not-allowed;
      }
    }
    .clickable-div.disabled > * {
      background: none;
    }
  }
}

.chat-message-tooltip {
  .ant-tooltip-inner {
    color: #2D3238;
  }
}
