.discharge-alert-info-tooltip {
  .ant-tooltip-content {
    width: max-content;
  }

  .ant-tooltip-inner {
    max-width: 485px;
    min-width: 321px;
    padding: 12px;
  }
}