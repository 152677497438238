$-card-width: 550px;

.auth-card-wrapper {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;;
}

.auth-card-component {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 25px 0px;
  gap: 36px !important;

  * {
    font-family: 'Noto Sans';
  }

  &__body {
    width: $-card-width;
    padding: 48px 60px 61px;

    &>div {
      width: 100%;
      max-width: 400px;
    }
  }

  .sub-title {
    margin-bottom: 0px;
  }

  .ant-divider.ant-divider-with-text {
    margin-top: 30px;
    margin-bottom: 24px;
  }

  .sub-title,
  .ant-divider-inner-text {
    font-size: 12px;
    font-weight: 500;
    color: var(--gray-scale-2);
    line-height: 22px;
  }

  .ant-divider-inner-text {
    line-height: 14px;
    margin: 6px 0px;
    color: var(--gray-scale-2);
  }

  .ant-form-item {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: $-card-width) {
  .auth-card-component__body {
    width: 100vw;
  }
}