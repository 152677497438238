.visitMethod-date {
  display: flex;
  block-size: 15px;
  color: var(--gray-scale-2);
  gap: 8px;
}


.outstanding-visit-header-component {
  color: var(--gray-scale-1);
  font-size: 14px;
}

.display-date-component {
  margin-top: 2px;
}

.edit {
  display: inline;
  position: absolute;
  right: 15px;
  color: #8C8C8C;
  .base-svg-icon {
    margin-bottom: 4px;
    margin-right: 100%;
  }
}