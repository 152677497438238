.big-cal-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  .abbr-date, .day {
    display: inline-block;
    color: var(--secondary-gray);
  }
  
  .abbr-date {
    font-size: 1.2em;
  }

  .day {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    font-size: 1.5em;
  }
}

.rbc-header.rbc-today {
  .day, .abbr-date {
    color: #107EFF;
  }
}

.rbc-header.rbc-today .day {
  background-color: rgba(16, 126, 255, 0.18);
}
