$sidebar-width: 220px;
$sidebar-collapsed-width: var(--layout-side-menu-width);

.sidebar-component {
  position: relative;
  height: 100vh;

  .ant-menu-item-selected,
  .sidebar-menu .ant-menu-item {
    border-radius: 0px !important;
  }

  .menu-item-shadow {
    height: 100%;
    position: absolute;
    top: 0;
    width:100% !important;
  }

  .ant-menu-item-icon {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-menu-title-content {
    display: none !important;
  }

  .sidebar-menu {
    width: $sidebar-width;
    min-height: calc(100vh - 55px);
    height: 100%;
    box-shadow: 1px 0px 2px 0px #00000026;
    padding: 10px 0px;
    overflow-x: hidden;

    .ant-menu-title-content {
      color: white;
    }

    .ant-menu-submenu-selected,
    .ant-menu-item-selected,
    .ant-menu-item:hover {
      background-color: unset;
    }

    .ant-menu-submenu-selected .menu-item-shadow,
    .ant-menu-item-selected .menu-item-shadow,
    .ant-menu-item:hover .menu-item-shadow {
      background-color: #FFFFFF;
      opacity: 0.3;
    }

    .ant-menu-submenu,
    .ant-menu-item {
      font-weight: 500;
    }

    .ant-menu-item {
      height: auto;
      border-radius: 6px;
      padding: 0 16px;
    }

    .menu-item-label {
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      color: white;
      white-space: break-spaces;
      text-align: center;
    }

    .ant-menu-item::after {
      border-right: unset;
    }

    .sidebar-menu-items-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }

  .sidebar-menu.collapsed {
    width: $sidebar-collapsed-width;

    .ant-menu-item {
      padding: 6px 0px !important;
    }
  }

  .ant-menu-item.sidebar-toggle-btn {
    padding: 0 !important;
  }

  .sidebar-toggle-btn {
    display: block !important;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .ant-menu-title-content {
      display: none !important;
    }
  }

  .sidebar-toggle-btn.collapsed {
    .ant-menu-title-content {
      display: none !important;
    }

    svg {
      transform: translate(-50%, -50%) scaleX(-1);
    }
  }
}