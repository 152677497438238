.Lab-search-select-component {
  width: 100%;

  .ant-select-selector {
    border-radius: var(--secondary-radius) !important;
  }
}

.ant-select-item-group:not(:first-of-type) {
  position: relative;
  padding-top: 30px;
    &::before {
      content: '';
      position: absolute;
      border-top: 1px solid #e8e8e8;
      top: 15px;
      left: 10px;
      right: 10px;
    }
}

.group-title {
  color: #2D3238;
  font-size: 14px;
  font-weight: 600;
}

.ant-select-item-option-grouped {
  padding-left: 12px;
}

.rc-virtual-list-scrollbar-thumb {
  background: #F0F0F0 !important; 
}