.tag {
  margin: 0;
  border-radius: var(--default-radius);
  padding: 6px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;

  svg {
    font-size: 17px;
  }
}

.bg-none {
  background-color: transparent !important;
}

.bg-oval {
  border-radius: 10px;
  padding: 2px 10px;
  font-size: 12px;
}

.tag-noFilled-icon {
  margin-right: 10px;
}

// use iconColor props
.red-halfFilled,
.blue-halfFilled,
.yellow-halfFilled,
.grey-halfFilled {
  border-radius: 50%;
}

.red-halfFilled {
  color: #DA6453;
  background-color: rgba(218, 100, 83, 0.1);
}

.blue-halfFilled {
  color: #1890FF;
  background-color: #E6F7FF;
}

.yellow-halfFilled {
  color: #EF973B;
  background-color: #f8f1e2;
}

.grey-halfFilled {
  color: #434343;
  background-color: #F5F5F5;
}

// use iconType - halfSolid
.success-halfSolid,
.error-halfSolid,
.warning-halfSolid,
.default-halfSolid {
  border-radius: 50%;
}

.success-halfSolid {
  background: rgba(59, 167, 103, 0.1);
  color: #247544;
}

.error-halfSolid {
  color: #DA6453;
  background-color: rgba(218, 100, 83, 0.1);
}

.warning-halfSolid {
  color: #EF973B;
  background-color: rgba(242, 200, 26, 0.1);
}

.default-halfSolid {
  background-color: #F4F6F8;
  color: #2D3238;
}

.blue-halfSolid {
  color: #1890FF !important;
}

// tagType color
.tag.tag-success {
  background: rgba(59, 167, 103, 0.1);
  color: #247544;
}

.tag.tag-error {
  background: rgba(218, 100, 83, 0.1);
  color: #DA6453;
}

.tag.tag-warning {
  background: rgb(255, 244, 232);
  color: #EF973B;
}

.tag.tag-processing {
  background: #F4F6F8;
  color: #2D3238;
}

.tag.tag-default {
  background: #F4F6F8;
  color: #D9D9D9;
}

// solid-dot color
.solid-dot-success {
  background-color: #3BA767;
}

.solid-dot-error {
  background-color: #DA6453;
}

.solid-dot-warning {
  background-color: #F2C81A;
}

.solid-dot-default {
  background-color: #D9D9D9;
}

.solid-dot-processing {
  background-color: #3177C9;
}

.solid-dot-done {
  background-color: #B5B9BE;
}

.solid-dot {
  display: inline-block;
  margin-right: 5px;
  border-radius: 50%;
}

.solid-dot-small{
  width: 8px;
  height: 8px;
}

.solid-dot-middle{
  width: 12px;
  height: 12px;
}

.tag-text,
.tag-text-color {
  color: #2D3238;
}
