.SymptomSeverityTagComponent {
  padding: 0;

  .solid-dot {
    border-radius: 20%;
    display: inline-block;
    margin-right: 10px;
    width: 12px;
    height: 12px;
  }
}
