.article-sent-history-table-container {
  .article-sent-history-table-component__label {
    font-size: 14px;
    line-height: 14.5px;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  
  .ant-table-body {
    margin: 0px !important;
  }

  .ant-table-thead th {
    background-color: #F7F7F7 !important;
  }

  .ant-table-column-title {
    font-weight: 600;
  }

  .article-select-option-info__link {
    padding: 0px;
  }
}