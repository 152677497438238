.FoodLogProviderCommentListComponent {
  padding: 10px 0;
  background-color: #fafafa;
  border: solid 1px #e3e3e3;
  border-radius: var(--default-radius);
}

.FoodLogProviderCommentComponent {
  margin: 15px;

  .FoodLogProviderCommentDateTime {
    color: #c5c5c5;
  }
}
