.hs-vitals-table.vitals-un-bordered-table .ant-table-tbody > tr
{
  &:nth-child(5) > td {
    border-bottom: none;
  }

  &:nth-child(4) > td {
    border-bottom: 1px solid #B5B9BE;;
  }
}
