.foodLogTabLabelComponent {
  position: relative;

  img {
    width: 100px;
  }

  .foodLogCheckMark {
    color: greenyellow;
    position: absolute;
    font-size: 20px;
    top: 2px;
    left: 2px;
  }
}
