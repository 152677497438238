.steps-component {
  &.ant-steps-vertical.ant-steps-small {
    .ant-steps-item-tail {
      display: none !important;
    }
  
    .ant-steps-item .ant-steps-item-content {
      min-height: unset;
    }
  
    .ant-steps-item-container {
      padding: 15px 0;
      border-bottom: 1px solid var(--gray-scale-4);
    }

    .ant-steps-item-description {
      padding: 0;
    }
  }
}
