.patient-create-insurance {
  .ant-alert.ant-alert-info {
    background: url('../../assets/images/note-taker-background.png') no-repeat;
    background-position: 100% 150%;
    background-color: var(--gray-scale-5);
    border-color: var(--gray-scale-5);
    border-radius: var(--default-radius);
  }

  .ant-alert.ant-alert-info:not(:first-child) {
    margin-top: 10px;
  }

  .ant-modal-mask,
  .ant-modal-wrap {
    position: absolute;
  }
}