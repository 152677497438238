.device-list {
  margin-top: 20px;

  .card {
    align-items: baseline;
    border: 1px solid #E7EAED;
    border-radius: 8px;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .device-item {
    width: 82px;
    max-height: 82px;
    text-align: center;

    img {
      width: auto;
      height: auto;
      max-width: 82px;
      max-height: 82px;
    }
  }
}