.slide-in-calendar-component {
  .ant-drawer-body {
    padding: 0;
  }

  .day-cal-toolbar .day-cal-toolbar-content {
    padding: 20px 15px;
    padding-bottom: 0;

    .calendar-dropdown-component {
      justify-content: space-between;
    }
  }
}
