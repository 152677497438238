.print-vital-title {
  font-size: 24px;
  font-weight: bold;
}

.print-vital-divider {
  border-top: 3px solid rgba(0, 0, 0, 0.06);
  margin-top: 10px;
  margin-bottom: 20px;
}
