.blood-pressure-device-assignment-warning-wrapper.disabled form {
  pointer-events: none;
}

.blood-pressure-device-assignment-warning {
  .ant-popover-content {
    max-width: 340px;
  }

  .ant-popover-inner-content {
    padding: 24px 16px;
  }

  .ant-popover-message-title {
    font-weight: 600;
  }
}