.discharge-form {
  margin-top: 10px;

  .highlight {
    color: var(--secondary-red);
  }

  input,
  .ant-select-selector {
    height: 38px !important;
  }

  input#validation {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  .ant-form-item:first-of-type {
    margin-bottom: 24px;
  }

  .ant-form-item {
    margin-bottom: 14px;
  }
}