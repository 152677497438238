.visit-view-component {
  &__divider {
    margin-bottom: 12px;
  }
  .ant-btn-primary:not([disabled]) {
    color: #fff;
    border-color: #1890FF;
    background: #1890FF;
    height: 40px;
    justify-content: center;
    align-items: center;
  }
}
