.medication-reminder__card {
  background: #F4F7F9;

  .ant-card-body {
    padding-top: 8px;
    padding-bottom: 12px;
  }

  .ant-divider {
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .ant-form-item {
    margin-bottom: 0px !important;
  }
}