.food-log-column-image-container{
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    margin-top: 15px;
    .image{
      height: 140px;
      width: 140px;
    }
  }

.viewBtn {
  color: var(--uc-blue);
  font-weight: 700;
}
