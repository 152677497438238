.standalone-input-component {
    display: flex;
    position: relative;
    background-color: var(--gray-scale-5);
    padding-left: 2px;

    .ant-input {
      background: transparent;
      padding: 7px 67px 7px 7px;
      border: none;
    }

    .standalone-input-buttons-group, .standalone-input-loading {
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        gap: 5px;

        button {
          width: 25px;
          height: 25px;
          padding: 0;

          .anticon {
            font-size: 12px;
          }
        }
    }
}
