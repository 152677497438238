.intervention-goal-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 18px;

  .goal-description {
    flex: 0.9;
    flex-wrap: wrap;
  }

  .meeting-status {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-size: 12px;
      color: #c4c2c2;
    }
  }
}

