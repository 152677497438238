.PatientTablesSiderContentComponent {
  .active {
    background-color: rgba(0,0,0,0.03);
  }

  .tab-title {
    margin-left: 14px;
    font-size: 12px;
  }
  
}
