.medical-alert-status-component {
  &.TODO {
    color: var(--secondary-red);
  }

  .ant-card {
    background: transparent;
  }

  .ant-card-body {
    padding: 0;
  }
}
