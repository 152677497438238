.controlled-text {
  color: #3BA767;
}

.uncontrolled-text {
  color: #DA6453;
}

.uncategorized-text {
  color: #6B7178;
}

.control-level-tag-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
}