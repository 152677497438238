.face-rate {
  .anticon {
    font-size: 50px;
    color: var(--gray-scale-3);
  }

  :not(.face-rate--active).anticon:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .anticon:active,
  .face-rate--active {
    color: var(--uc-blue);
  }
}