.articles-container {
  .articles-container__title {
    font-weight: bold;
    margin: 10px 0px;
    font-size: 16px;
    color: black;
  }

  .article-sent-history-table-component {
    margin-top: 25px;
  }
}