.checkbox-select-component__popup {
  .ant-select-item.ant-select-item-option {
    .ant-select-item-option-content {
      margin-left: 10px;
    }

    display: flex;
    flex-direction: row-reverse;
  }
}
