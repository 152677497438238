.goal-description-selector{
  .ant-select-selector {
    height: auto !important;
    .ant-select-selection-item {
      white-space: normal !important;
      line-height: 1.5;
    }
  }
}

.ant-select-item-option-content {
  white-space: normal !important;
  word-break: normal !important;
}
