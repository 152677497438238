.order-info__image {
  max-width: 90px;
  min-width: 90px;
  max-height: 66px;
  text-align: center;

  img {
    max-width: 90px;
    max-height: 66px;
    width: auto
  }
}

.order-info__title {
  display: flex;
  flex-wrap: wrap;
}