.cgm-vital-container {
  position: relative;

  .cgm-vital-container__header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
    padding-bottom: 20px;
  }
}