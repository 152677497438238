.medical-alert-component {
  align-items: baseline;
  border: 1px solid #E7EAED;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  margin-bottom: 18px;

  .ant-card-head {
    padding: 16px;
  }

  .ant-card-head-title {
    padding: 0%;
  }

  .ant-card-body {
    padding: 0%;
  }

  .ant-card-head-title {
    font-weight: 400;
  }
}