.ticketPriority, .ticketPriorityDropdown {
  &.low, .low {
    color: var(--secondary-green);
  }
  &.medium, .medium {
    color: var(--secondary-orange);
  }
  &.high, .high {
    color: var(--secondary-red);
  }
}
