.message-ai-draft-btn {
  margin-top: 20px;
  border-radius: 30px;
  color: var(--primary-blue-6) !important;
  border-color: var(--primary-blue-6) !important;
}

.ai-title {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-blue-6);
}

.ai-info {
  color: var(--primary-blue-6);
}
