.wrap-modal-component {
  .ant-modal-mask {
    pointer-events: none;
  }

  &.ant-modal-wrap {
    pointer-events: none;
  }

  .ant-modal {
    pointer-events: all;
    padding-bottom: 0px;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .draggable-title {
    cursor: move;
    user-select: none;
  }

  .ant-modal-close {
    display: none;
  }

  .title-close-button {
    color: var(--grayscale-2);
    font-size:18px;
    cursor: pointer;
  }

  .ant-modal-header {
    border-bottom: transparent;
    background-color: #f1f3f4;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0px 0px;
  }

  .modal-component {
    transition: none;
    position: absolute;
  }

  .modal-component-header {
    cursor: move;
    height: 40px;
    padding: 10px 24px;
  }

  .ant-modal-footer {
    border-top: transparent;
  }

}
