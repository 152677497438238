.PauseServiceAlertComponent {
  button {
    width: 100%;
    height: auto;
    padding: 0;
    border: none;

    .alert-card-component {
      justify-content: flex-start;
      padding: 6px 26px;
    }
  }
}
