.medication-dosage__item {
  flex-basis: 100%;

  .ant-form-item-row {
    flex-direction: column;

    .ant-form-item-label {
      text-align: left;
    }
  }
}