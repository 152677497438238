.patient-profile-page {
  $padding: 0px 16px;
  $gap: 8px;

  padding-bottom: 20px;

  &__content {
    display: flex;
    overflow: hidden;
    height: 100%;
    padding: $padding;
  }

  &__alerts {
    padding: $padding;

    & > * {
      margin-bottom: 5px;
    }
  }

  .ant-card {
    border-radius: var(--secondary-radius);
    overflow: auto;
  }

  &.screen-size-component {
    background-color: var(--background-gray);

    >.screen-size-child-component {
      overflow: hidden;
    }
  }

  &__main {
    flex: 1
  }

  &__main-body,
  &__body-wrapper {
    height: 100%;
  }

  &__main-body {
    overflow: hidden;
  }

  &__main-content {
    display: flex;
    gap: $gap 0px;
  }

  &__body-wrapper {

    .fixedChildComponentWrapper {
      overflow: hidden;
    }
  }

  &__body {
    height: 100%;

    .ant-card-body {
      padding: 12px 24px !important;
    }

    .anchor-component-main {
      max-width: 100%;
    }

    .anchor-component__children {
      // prevent horizontal scrolling
      padding-right: 19px !important;
    }
  }

  $right-width: 436px;

  &__extra-right {
    margin-left: $gap;
    margin-right: 0;
    max-width: $right-width;
  }

  .fixedChildComponentWrapper {
    margin: 0;
  }

  &__body-wrapper.fixedChildComponent.scrollable {
    flex: unset;
    overflow-x: hidden;
  }

  &__extra {
    >.ant-card {
      height: 100%;
      overflow: hidden;
    }

    .outstanding-component {
      width: $right-width;
      border: 1px solid #f0f0f0;
      padding: 24px;
    }
  }

  &__extra-body {
    overflow: hidden;
    border: none;
  }

  &__extra-body>.ant-card-body {
    padding: 0px;
  }

  .ant-card-body {
    height: 100%;
  }

  .ant-tabs-content {
    overflow: auto;
  }
}


.normal-page .patient-profile-page {
  padding: 12px;
}