.device-monitor-form-popconfirm {
  .ant-popover-content {
    max-width: 440px;
  }

  .ant-popover-inner {
    padding: 24px 32px;
    padding-left: 18px;
  }

  .ant-popover-message-icon {
    margin-right: 20px;
    padding-top: 2px;
    
    &>span {
      font-size: 20px;
    }
  }

  .ant-popover-message-title * {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-popover-buttons {
    button {
      width: 60px;
      height: 32px;
    }
  }
}