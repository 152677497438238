.phone-exist-warning-modal {
  .ant-modal-confirm .ant-modal-body {
    padding: 24px;
  }

  .ant-modal-confirm-body{
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-modal-confirm-content {
      margin-left: 0 !important;
    }
  }
  .ant-modal-confirm-btns {
    text-align: center;
  }
}
