.medical-alert-priority-level-component  {
  border-radius: 15px;
  height: 28px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  font-size: 12px;


  &.LOW {
    background-color: #E5F9F8;
    color: #00C8BC;
  }

  &.MEDIUM {
    background-color: #FFF4E8;
    color: #EF973B;
  }

  &.HIGH {
    background-color: #FFF1F0;
    color: #DA6453;
  }
}
