.message-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-left: 20px;

  &:not(:last-child) {
    margin-bottom: 1.5em;
  }

  &__content {
    padding-left: 18px;
    padding-right: 14px;
    width: 100%;
    overflow: hidden;

    b {
      font-size: 16px;
    }

    p {
      margin-bottom: 0;
      overflow: hidden;
      // white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--text-gray-primary);
    }
  }

  &__content>div:not(.message-item__sender)>div,
  &__content>div:not(.message-item__sender)>span,
  &__content>div:not(.message-item__sender)>p {
    background-color: #FFFFFF;
  }

  &__date {
    width: 120px;
    color: var(--text-gray-primary);
    text-align: right;
  }

  &__pdf-link {
    padding: 0;
  }

  &__image-button {
    height: auto;
    padding: 10px !important;
  }

  &__image-width {
    min-width: 280px;
    width: calc(100% - 168px);
  }

  &__message-content {
    font-size: 14px;
    min-height: 32px;
    padding: 4px 12px;
    border-radius: 6px;
    border: 1px solid;
    display: inline-block;
    white-space: pre-line;
    border-color: var(--border-gray);
    color: var(--gray-scale-2);
  }

  &__image-content {
    min-width: 280px;
    width: calc(100% - 168px);
  }

  &__message-primary {
    color: #FFFFFF !important;
    background-color: var(--primary-blue-6) !important;

    &::selection {
      background-color: var(--uc-blue);
    }
  }

  &__message-highlight {
    border-color: rgb(80, 176, 231);
  }

  &__sender {
    color: var(--gray-scale-2);
    font-size: 12px;
    line-height: 2;
    max-width: 100%;
  }

  &__pdf-content,
  &__image-content {
    .ant-btn.ant-btn-link {
      padding: 0;
      white-space: pre-wrap;
      text-align: left;
      height: auto;
    }
  }

  &__image-content {
    .ant-btn.ant-btn-link {
      margin: 18px 0px;
      width: 100%;
    }

    img {
      width: 160px;
      max-height: 160px;
    }
  }
}

.w-full {
  width: 100%;
}