.updated-by-at-component {
  display: flex;
  gap: 5px;
  color: var(--gray-scale-3);

  &.reverse {
    .updated-by {
      order: 1
    }

    .updated-at::after {
      content: ', ';
    }
  }
}
