.title {
  color: var(--gray);
  font-weight: 700;
  font-size: 18px;
}

.title.secondary {
  font-size: 14px;
}

.sub-title {
  font-size: 14px;
  color: var(--secondary-gray);
  margin-bottom: 10px;
}

.page-title {
  font-size: 20px;
  font-weight: 700;
  color: var(--gray-scale-1);
  margin: 0;
}
