.foodLogPatientInfoComponent {
  margin-bottom: 20px;

  .patientName {
    font-size: 22px;
    font-weight: 600;
    word-wrap: break-word;
    text-align: left;
    color: var(--uc-blue);
  }
}