.vitals-monitoring-scheduling-form-component {
  $width: 150px;
  
  .vitals-monitoring-label {
    width: $width * 2 + 20px;
  }
  
  .ant-input-number {
    width: $width;
  }
  
  .ant-select {
    width: $width;
  }

  .bg-vital {
    
    .vitals-monitoring-label {
      width: 100%;
    }

    .ant-select {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
