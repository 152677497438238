.drag-drop-upload {
  .ant-upload-drag-container {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .drag-drop-upload__icon svg {
    width: 40px;
    height: 40px;
  }

  .drag-drop-upload__text {
    font-size: 16px;
    line-height: 24px;
    color: #000000D9;
  }

  .drag-drop-upload__hint {
    font-size: 14px;
    line-height: 22px;
    color: #00000073;
  }
}