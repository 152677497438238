.lab-status-component {
  display: flex;
  align-items: center;
  gap: 7px;

  &.HIGH {
    color: #CB6B59;
  }
  &.LOW {
    color: #448EF7;
  }
  &.NORMAL {
    color: #5AC5BC;
  }
}
