.CgmGoalFormComponent {
  max-width: 90px;

  &__input-number {
    width: 80px;

    .ant-input-group .ant-input { 
      text-align: center; 
    }
  }

  &__status {
    margin-left: auto;
    margin-top: 3px;
  }

  &__reset-bottom {
    margin-top: 18px;
    background: transparent !important;
  }

  &__reset-button-icon {
    transition: color 0.2s ease-in-out;
  }
  
  &__reset-button-icon:hover {
    color: #107EFF;
  }

  .ant-input-group-addon {
    padding: 0 6px;
  }

  .ant-form-item-explain {
    font-size: 12px;
    white-space: nowrap;
  }
}
