.cgm-chart-view-switch {
  width: max-content;
  padding: 2px;
  border: 1px solid var(--gray-scale-4);
  background-color: var(--gray-scale-4);
  border-radius: 4px;
  display: flex;
  gap: 2px;

  .radio-button-component {
    width: 120px;
  }

  .radio-button-component.active-btn {
    border-color: transparent;
    background-color: #FFFFFF;
    font-weight: 600;
  }

  .radio-button-component:not(.active-btn) {
    border: unset;
    background-color: transparent;
  }
}