.CareNotesComponent{
  .year-title {
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 700;
  }
  .month-title  {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
  }
}
