.vitals-component {
  position: relative;

  .vitals-date-range {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
