.medicationNameWrapper {
  position: relative;
  display: flex;
  align-items: center;
  .ant-select-selector .ant-select-selection-search input.ant-select-selection-search-input {
   white-space: nowrap;
   width: 89%;
   overflow: hidden;
   text-overflow: ellipsis;
   display: block;
  }
 .searchIcon {
    margin-left: -20px;
    z-index: 1;
 }
 .ant-select-clear {
    right: 24px;
 }
}
