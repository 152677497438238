.articles-search-component {
  &__label {
    font-size: 14px;
    line-height: 14.5px;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  .search-dropdown__select {
    .ant-select-selector {
      // prevent selection overlay send button
      padding-right: 75px;
      padding-bottom: 10px;
    }

    // send button style
    .ant-select-clear {
      opacity: 100;
      right: 60px;
      top: 10px;
    }
  }
}

.search-dropdown__popup {
  .article-select-option-header .ant-tabs-nav {
    margin-bottom: 0px;
  }

  .pagination-select-option {
    text-align: right;
  }
}