.with-preview-env-warning-banner-style {
  --preview-env-banner-height: 32px;
}

.preview-env-warning-banner {
  position: absolute;
  top: calc(var(--layout-header-height) - var(--preview-env-banner-height));
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--secondary-orange);
  background: var(--light-orange);
  z-index: 3;
  height: var(--preview-env-banner-height)
}