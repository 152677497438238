.assignDeviceFormComponent {
  display: flex;
  height: 100%;

  .ant-form-item-label label {
    color: var(--gray);
    font-weight: 600;
  }

  .shipping-address label {
    font-weight: 400;
  }

  .shipping-address .ant-form-item {
    margin-bottom: 16px;
  }
}
