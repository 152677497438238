.patient-enrollment-vitals-and-programs {
  .programs-checkbox-group .ant-checkbox-wrapper {
    margin-bottom: 10px;
  }
}

.confirm-patient-clinic {
  .ant-select {
    width: 100%;
  }
}