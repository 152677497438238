.forgot-password-component {
  .ant-form-item-explain-error {
    text-align: left;
  }

  &.ant-btn-link {
    padding: 0px;
    margin-bottom: 16px;
    font-size: 14px;
    color: var(--uc-blue);
  }
}