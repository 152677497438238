.AnnouncementListComponent {
  .announcement-category {
    padding: 16px 24px;
  }

  .announcement-list {
    max-height: 600px;
    overflow: auto;
  }
}
