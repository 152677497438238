.CommentWAvatarComponent{
  .EditDeleteButtons {
    display: flex;
    gap: 7px;
    align-items: center;

    &, button > span {
      color: var(--text-gray-primary);
    }

    .DotDivider {
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: var(--text-gray-primary);
    }
  }

  .EditButtons {
    display: flex;
    gap: 7px;
    align-items: center;

    .CancelButton {
      color: var(--text-gray-primary);
    }
  }

  .EditDeleteButtons, .EditButtons {
    button {
      padding: 0;
    }
  }
}
