.patient-profile-tab-label {
  display: flex;
  flex-direction: column;
  min-width: 100px;

  .patient-profile-tab-label__item {
    padding: 8px 16px 8px 12px;
  }

  .patient-profile-tab-label__item:hover {
    background: var(--uc-blue-secondary);
  }

  .patient-profile-tab-label__item__active {
    font-weight: 700;
  }
}

.patient-profile-tab-label__popover {
  z-index: 1051;

  .ant-popover-inner-content {
    padding: 8px 0px !important;
  }
}