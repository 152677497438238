.a1c-test-window {
  margin-bottom: 10px;
  .tag {
    padding: 0;
  }
}

.a1c-system-alert {
  margin-left: 22px;
  color: gray;
}

// antd date expire warning
.transcribe-form-component {
  .ant-form-item-explain-warning {
    width: 100%;
  }
  .ant-input-affix-wrapper {
    border-radius: var(--default-radius);
  }
}

// antd form
.transcribe-form-component-engagement {
  .ant-form-item {
    margin-bottom: -8px;
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
}

// input box & close icon
.a1c-note-input-wrapper {
  margin-top: 10px;
  margin-left: 22px;
  width: 380px;
  position: relative;
  // antd input area
  textarea.ant-input {
    background-color: #fbfbfb;
    border-color: #fbfbfb;
  }
  .ant-input:focus, .ant-input-focused {
    border-color: #fbfbfb;
    box-shadow: 0 0 0 0px;
    outline: 0;
  }
  .ant-input:hover, .ant-input-hover {
    border-color: #fbfbfb;
    box-shadow: 0 0 0 0px;
    outline: 0;
  }
  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
    border-color: #fbfbfb;
  }
}

.a1c-note-close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #bdbaba;
}

.a1c-leave-note-link {
  margin-left: 22px;
}

.a1c-note-submit-button {
  margin-top: 3%;
  margin-left: 22px;
}

.a1c-notes-display {
  width: 100%;
  margin-top: 10px;
  padding-left: 22px;
}

.a1c-note-container {
  background-color: #fbfbfb;
  height: 80px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding:10px 20px;
  // antd timeline
  .ant-timeline-item {
    padding-bottom: 0;
  }
  .ant-timeline-item-head-blue {
    color: rgb(150, 150, 150);
    border-color: rgb(150, 150, 150);
  }
  .ant-timeline-item-head {
    width: 8px;
    height: 8px;
    background-color: rgb(150, 150, 150);
  }
  .ant-timeline-item {
    color: rgb(150, 150, 150);
  }
  .ant-timeline-item-tail {
    left: 3.5px;
    border-left: 1px dashed rgb(193, 193, 193);
  }
}

.transcribe-a1c-form{
  padding: 24px;
}