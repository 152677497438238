.link-button {
  padding: 0px;
  width: max-content;
  color: 'var(--text-gray)';

  &.use-blue-color:not(:disabled) {
    color: var(--uc-blue);
    
    &:hover, 
    &:focus {
      color: #40A9FF;
    }
  }

  &.underlined span {
    text-decoration: underline;
  }

  &.underline-on-hover:hover span {
    text-decoration: underline;
  }
}