.label-with-value-component {
  align-items: flex-start;
  flex-wrap: inherit;

  .label-text {
    font-size: 14px;
    color: var(--gray-scale-1);
    font-weight: 600;
  }

  .value-text {
    font-size: 14px;
    color: var(--gray-scale-1);
    font-weight: 400;
  }

  .full-content-value-text {
    font-size: 14px;
    color: var(--gray-scale-1);
    font-weight: 400;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.tooltip-style {
  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-content {
    position: relative;
    margin-top: -10px;
    border: 1px solid var(--gray-scale-3);
    border-radius: 4px;
  }

  .ant-tooltip-inner {
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: var(--gray-scale-2);
    border-radius: 2px;
  }
}
