.ticket-activity-history-component {
  padding-left: 12px;
  padding-right: 12px;
  

  &:hover, &.active {
    background-color: var(--background-gray);
  } 
  
  &,
  strong,
  div,
  .ant-collapse-expand-icon,
  .ant-collapse-header-text {
    color: var(--secondary-gray);
  }
}

.ticket-single-activity-log-component {
  word-wrap: break-word;
  word-break: break-word;
}
