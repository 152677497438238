.tech-worklist-guide {
  margin-bottom: 40px;
  .card-text-component.card-text-large {
    margin-bottom: 18px;
    .anticon {
      margin-right: 5px;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 30px;
  }
}

.tech-worklist-guide-step {
  margin-bottom: 14px;

  .tech-worklist-guide-step-number {
    width: 50px;
    min-width: 50px;
    font-weight: 450;

    .hidden {
      visibility: hidden;
    }
  }
}
