.current-treatment-checkbox-group {
  --item-width: 180px;
  --column-gap: 24px;
  max-width: calc(var(--item-width) * 2 + var(--column-gap));
  padding-top: 4px;

  &__item {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-space-item {
    width: var(--item-width);
  }
}