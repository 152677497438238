.translated-text-comment {
  .original-text-comment {
    font-size: 12px;
    border-left: 2px solid #e3e3e3;
    padding-left: 10px;
    color: #6B7178;

    .fs-italic {
      color: #6B7178;
    }
  }

  .message-original-text-comment {
    font-size: 14px;
    border-left: 2px solid #e3e3e3;
    padding-left: 10px;
    user-select: text;

    &.patient {
      color: #FFFFFF;

      .fs-italic {
        color: #E7EAED;
      }
    }

    &.not-patient {
      color: #000000;

      .fs-italic {
        color: #6B7178;
      }
    }
  }
}
.translated-text-comment::selection{
    background: rgb(78, 230, 247);
    color: black;
}