.render-select-patients-to-take-care {
  margin-top: 29px;
  margin-bottom: 29px;

  .render-title {
    color: var(--Grayscale-1, #2D3238);
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.render-radio-group {
  margin-right: 50px;
}

.render-text {
  color: var(--Grayscale-2, #6B7178);
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}