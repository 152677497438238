.anchor-component-main {
  height: 100%;
  &.anchor-component-main__hide-anchors {
    .anchor-component__anchor {
      margin-left: 0px !important;
      visibility: hidden;
      width: 0px !important;
    }

    .anchor-component__children {
      width: 100% !important;
    }
  }
}

.anchor-component {
  $anchorWidth: 160px;
  height: 100%;
  display: flex;
  overflow: auto;
  position: relative;

  &__anchor {
    margin-left: 5px;
    width: $anchorWidth;
    position: sticky;
    top: 0;
    max-height: 100%;
  }

  &__children {
    width: calc(100% - $anchorWidth);
    padding-right: 14px;
  }

  .ant-anchor-ink:before {
    width: 0px;
  }

  .ant-anchor-ink-ball {
    border: 5px solid var(--uc-blue);
    transform: translateX(-35%);
  }

  .ant-anchor-link {
    padding-left: 16px;
    margin-bottom: 8px;
  }

  .ant-anchor-link-title {
    font-size: 15px;
    line-height: 22px;
    color: #595959;

    &-active {
      font-weight: 700;
      color: #000000;
    }
  }
}