.llm-dragdrop-upload {
  .fixedChildComponentWrapper {
    height: auto;
  }
}

.llm-dragdrop-upload--guide {
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
}