.unlink-account-button {
  color: #2D3238;
  font-weight: 500;

  span {
    text-decoration: underline;
  }
}

.linking-status-message {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  border: 1px solid #F4F6F8;
  background-color: #F4F6F8;
  border-radius: 6px;
  padding: 10px;
}

.linking-status-info {
  &.secondary-gray {
    color: #6B7178;
  }

  &.expired {
    color: #EF973B;
  }

  &.failed {
    color: #DA6453;
  }

  &.success {
    color: #3BA767;
  }

  a {
    margin-left: 5px;
    color: inherit;
    text-decoration: underline;
  }
}