.llm-view-source {
  top: 10px;
  max-width: fit-content !important;
  padding: 0px;

  .ant-modal-header {
    z-index: 2; // prevent zoomed image overlay
  }

  .ant-layout-sider {
    z-index: 1; // prevent zoomed image overlay
  }

  .ant-modal-body {
    padding: 0;
    display: flex;
    overflow: hidden;
  }
}

.llm-view-source__wrapper {
  display: flex;
  padding-right: 0px;
  max-height: 90vh;
  overflow: auto;
}

.llm-view-source__content {
  padding: 10px;
  flex: 1;
  text-align: left;
  overflow: auto;

  img {
    width: 100%;
    max-width: 100%;
  }
}

.llm-view-source__navigator {
  height: auto !important;
  min-height: 150px;
  padding: 10px;
  background-color: transparent;

  .fixedChildComponent.scrollable .fixedChildComponentWrapper {
    height: max-content;
  }

  .ant-layout-sider-trigger {
    width: unset !important;
    right: -14px !important;
    background: transparent;
    user-select: none;
    display: flex;
  }

  &.ant-layout-sider-collapsed {
    // .ant-layout-sider-children {
    //   // prevent jumping trigger when collapsed
    //   display: inherit;
    //   visibility: hidden;
    // }
  }
}

.llm-view-source__navigator:not(.ant-layout-sider-collapsed) {
  flex-basis: 25% !important;
  min-width: min(330px, 25%) !important;
}

.llm-view-source__content {
  background-color: var(--gray-scale-4);
}

.llm-view-source__image:not(:last-child) {
  margin-bottom: 24px;
}

.llm-view-source .react-draggable {
  box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 20px 1px;
}