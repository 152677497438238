.cgm-date-picker__popup {
  .ant-picker-cell-inner {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  td.ant-picker-cell {
    padding: 0px 0px 3px !important;
  }

  .ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-range-end .ant-picker-cell-inner {
    margin-top: 1px;
  }

  .ant-picker-cell-inner,
  .ant-picker-cell-inner::after,
  .ant-picker-cell::before,
  .ant-picker-cell::after {
    height: 31px !important;
    line-height: 30px !important;
  }

  .ant-picker-cell-inner,
  .ant-picker-cell::before,
  .ant-picker-cell::after {
    top: 46% !important
  }

  .date-has-cgm-data-dot {
    border: 3px solid #107EFF;
    border-radius: 50%;
  }

  .ant-picker-cell-inner .date-has-cgm-data-dot {
    margin-top: -6px;
  }

  .ant-picker-cell-range-start .date-has-cgm-data-dot,
  .ant-picker-cell-range-end .date-has-cgm-data-dot {
    border-color: #FFFFFF;
  }

  .ant-picker-panel.ant-picker-panel-has-range {
    border-bottom: unset;
  }

  .ant-picker-footer-extra {
    margin-top: -10px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .alert-card-component {
    padding: 6px 14px;
  }
}