.pinned-patients-component {
  display: flex;
  gap: 16px;
  overflow: auto;
  padding: 10px;

  .ant-avatar {
    background-color: #e3e4e5;
    outline: var(--gray-scale-3) 1px solid;
    color: var(--gray-scale-2);
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    width: 28px;
    height: 28px;
    line-height: 28px;
  }

  .selected {
    outline-color: var(--ant-blue);
    background-color: #e8f1fa;
    font-weight: bold;
    color: var(--ant-blue);
  }
}
