.onboarding__assignees {
  margin: 0 10px;
  margin-bottom: 30px;
  border: 1px solid rgb(240, 240, 240);
  padding: 24px;

  > span {
    color: var(--text-gray);
  }

  .ant-select-selector {
    width: 252px !important;
  }

  .ant-form-item-no-colon {
    font-size: 12px !important;
    height: auto !important;
  }
}