.patient-info-card-component {
  border-radius: var(--secondary-radius);
  background: #FFFFFF;

  &__content {
    padding: 12px 24px !important;
  }

  .patient-name {
    font-size: 24px;
    line-height: 28px;
    color: var(--gray);
  }

  .menu-dot-btn {
    background: #d9d9d9;
    height: 36px;
    width: 40px;
    justify-content: center;
    align-items: center;
  }

  .ant-btn-primary.chat-unread {
    background: var(--secondary-orange);
    border-color: var(--secondary-orange);
    transition: all 0.3s ease-out
  }

  .ant-badge-dot {
    width: 10px;
    height: 10px;
  }

  .row-pt15 {
    padding-top: 15px;
  }

  .row-pl15 {
    padding-left: 15px;
  }

  .ant-btn-primary {
    color: #fff;
    border-color: #1890FF;
    background: #1890FF;
    height: 36px;
    justify-content: center;
    align-items: center;
  }

  .ant-row {
    margin-bottom: 0;
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-card-bordered {
    padding: 10px;
  }
}

.patient-info-card-component-wrapper {
  .patient-info-card-component, .unenrolled-patient-info-card-component {
    .patient-info-properties {
      .label-text, .full-content-value-text {
        font-size: 14px;
        font-weight: normal;
      }

      .label-with-value-component {
        align-items: left;

        & .value-text {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }
}
