.add-offline-time-drawer-component {

  .ant-drawer-footer {
    flex-shrink: 0;
    padding: 20px 20px 0 0;
    border-top: 1px solid var(--gray-scale-4);
  }
  .ant-space {
    width: 100%;
    justify-content: flex-end;
    display: inline-flex;
  }

  .ant-btn-primary {
    color: #fff;
    border-color: #1890FF;
    background: #1890FF;
    height: 40px;
    justify-content: center;
    align-items: center;
  }

  .ant-btn-default {
    color: var(--gray-scale-1);
    border-color: var(--gray-scale-2);
    background: #fff;
    height: 40px;
    justify-content: center;
    align-items: center;
  }

  .ant-picker {
    width: 165px;
  }

  .ant-input-number-wrapper {
    width: 165px;
  }
}
