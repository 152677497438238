@use './styles/zIndex.scss';

// --- Message
.ant-message-notice-content {
  background-color: rgb(71, 71, 71, 0.9);
  border-radius: 8px;
  padding: 5px 16px;
  letter-spacing: 0.5px;
  color: #FFF;
}

.ant-popover-inner,
.ant-table-filter-dropdown {
  border-radius: var(--secondary-radius);
}

// make tale (size middle) round
.ant-table.ant-table-middle {
  border: 1px solid #E7EAED;
  border-radius: var(--middle-table-radius);

  // table header
  .ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: var(--middle-table-radius);

    // move the text to the right
    // with filter or sorter
    .ant-table-filter-column,
    .ant-table-column-sorters {
      margin-left: 10px;
    }
  }

  // move the first column of table header text to the right a lil bit
  // for no filter nor sorter column
  .ant-table-thead>tr>th:first-child {
    padding-left: 20px !important;
  }

  .ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: var(--middle-table-radius);
  }

  //hover
  .ant-table-tbody>tr:last-child>td:first-child,
  .ant-table-tbody>tr:last-child.ant-table-row:hover>td:first-child {
    border-bottom-left-radius: var(--middle-table-radius);
  }

  .ant-table-tbody>tr:last-child>td:last-child,
  .ant-table-tbody>tr:last-child.ant-table-row:hover>td:last-child {
    border-bottom-right-radius: var(--middle-table-radius);
  }

  // table body first column
  .ant-table-tbody>tr>td:first-child {
    padding-left: 20px;
  }
}

.ant-form-item {
  margin-bottom: 10px;

  // Selects all ant-form-item that is a descendant of ant-form-item
  // eg. FormItem with dependencies: <FormItem dependencies={[hook.getName('memberId')]}
  .ant-form-item {
    margin-bottom: 0px;
  }
}

// Checkbox group button style
.checkbox-group-btn-style {
  .ant-checkbox-wrapper {
    border: 1px solid #BFBFBF;
    border-radius: var(--default-radius);
    padding: 10px 20px;

    &.block {
      width: 100%;
    }
  }
}

// drawer
.ant-drawer-header-title {
  flex-direction: row-reverse;
}

// notification
.ant-notification-notice {
  border-radius: var(--default-radius);
}

//Put the red dot of required fields to the end of the label
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.hasRequiredLabel::after,
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  content: "*";
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}

.hide-required-mark .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  content: '';
}

.ant-table-wrapper {
  margin-bottom: 32px;
}

.ant-table-pagination.ant-pagination {
  margin-right: 10px;
}

.ant-form-item-no-margin {
  .ant-form-item {
    margin: 0px;
  }
}

// synchronize the dropdown style for provider and clinic
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #bae7ff;
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  font-weight: bold;
}

.button-in-dropdown {
  .ant-dropdown-menu-item {
    padding: 0 !important;

    .ant-btn {
      width: 110px;
      height: 32px;
      padding-left: 30px !important;
      text-align: left;
    }
  }
}

// mainly make modal on top of popover in calender(discard/delete)
.ant-modal-mask {
  z-index: zIndex.$ant-modal;
}

.ant-modal-wrap {
  z-index: zIndex.$ant-modal;
}

.ant-popover,
.ant-popconfirm {
  z-index: zIndex.$ant-modal;
}

// Switch color
.ant-switch-checked {
  background-color: var(--green);
}

td.ant-table-column-sort {
  background-color: inherit;
}

.ant-table-thead th.ant-table-column-sort {
  background-color: #fafafa;
}

.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}

.ant-drawer-header-title {
  // align all drawer header title when there is no title
  min-height: 22px;
}

// link for downloading files
a[download] {
  &:hover {
    color: var(--gray-scale-2);
    text-decoration: underline;
  }
}

.ant-upload-list-text-container {
  border: 1px solid var(--border-gray);
  border-radius: var(--default-radius);
  padding: 6px;
  margin-top: 10px;

  &:hover .ant-upload-list-item {
    cursor: pointer;
    opacity: 0.8;
  }

  .ant-upload-list-item {
    margin-top: 0px;
    color: var(--uc-blue);
    font-weight: 500;
  }

  .ant-upload-list-item .ant-upload-list-item-info {
    background-color: transparent;
  }

  .ant-upload-text-icon {
    display: none;
  }

  .ant-upload-list-item-card-actions-btn {
    color: var(--text-gray-primary);
    opacity: 1; // always show
  }
}

.ant-select-underline-selection {
  .ant-select-selector {
    border: unset !important;
    box-shadow: unset !important;
  }

  .ant-select-selection-item {
    text-decoration: underline;
  }
}

.ant-tooltip.no-max-width {
  max-width: inherit !important;
}
