$padding-right: 25px;

.card-w-horizontal-section {
  background-color: white;
  border: 1px solid var(--light-gray);
  margin-bottom: 10px;
  border-radius: 8px;

  .card-w-horizontal-section-item {
    border-bottom: 1px solid var(--light-gray);
    padding: 20px 0;
  }
  
  .card-w-horizontal-section-item.no-divider {
    border: unset;
  }
  
  .card-w-horizontal-section-item.header-item {
    padding-bottom: 10px;
  }

  .card-w-horizontal-section-item:last-of-type {
    border-bottom: unset;
  }
}

.card-w-horizontal-section.full-divider-line {
  padding: 0;

  .card-w-horizontal-section-item {
    padding: 10px $padding-right;
  }
}

.card-w-horizontal-section-item.extra-item {
  padding: 10px;
  padding-bottom: 0;
  display: flex;
  justify-content: flex-end;
}

