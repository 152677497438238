.outstandingVisitComponent {
  border: 1px solid #E7EAED;
  border-radius: 8px;
  height: auto;
  margin-bottom: 20px;
  box-shadow: 0px 0px 0px 1px #ffffff;
  box-sizing: border-box;
}


.ant-card-head-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.grayscale {
  color: #2D3238;
}


