.latest-note,
.not-latest-note {
  padding-bottom: 0;
  color: rgb(150, 150, 150);
  .ant-timeline-item-tail {
    left: 4px;
    border-left: 1px dotted rgb(193, 193, 193);
    top: 15px;
    height: 100%;
  }
}

.latest-note {
  color: black;
  font-weight: 400;
}

.latest-update-time {
  color: black;
  font-weight: 600;
  margin-bottom: 20px;
}

.history-item {
  color: black;
  padding: 6px 0;
  margin-bottom: 25px;
}

.latest-note .ant-timeline-item-head-blue,
.not-latest-note .ant-timeline-item-head-blue {
  width: 8px;
  height: 8px;
}

.latest-note .ant-timeline-item-head-blue {
  color: #1890ff;
  border-color: #1890ff;
  background-color: #1890ff;
}

.not-latest-note .ant-timeline-item-head-blue {
  color: rgb(150, 150, 150);
  border-color: rgb(150, 150, 150);
  background-color: rgb(150, 150, 150)
}

.history-list-component {
  .ant-timeline-item:last-child .ant-timeline-item-tail {
    display: none;
  }
}