.patient-health-condition-control-level-detail-component {
  $padding: 15px 25px 10px 25px;
  padding: $padding;
  background-color: #fafafa;
  border-radius: 6px;
  margin-top:12px;

  .title {
    font-family: Noto Sans;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
  }

  .grey-separator {
    margin: 20px (-$padding);
    border-top: 1px solid #f0f0f0; /* Create a thin grey line */
    color: #f0f0f0;
    padding: 0;
  }

  .explanation-text {
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: left;    
  }
}
