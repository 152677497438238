.order-device-supplies-drawer {
  .ant-drawer-header {
    border-bottom: unset;
  }

  .ant-drawer-header-title {
    align-items: flex-start;
  }

  .ant-modal-mask {
    pointer-events: auto;
  }

  .ant-modal-mask,
  .ant-modal-wrap {
    position: absolute;
  }

  .order-info-content {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
    min-width: 30%;
    max-width: 30%;
  }

  .order-info-content:first-child {
    min-width: 45%;
    max-width: 45%;
  }
}

.order-device-supplies-drawer-title {
  padding-top: 24px;

  .title {
    font-size: 20px;
  }
}