.dashed-children {
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.underlined-children {
  text-decoration-line: underline;
  text-decoration-style: solid;
}

.wand-icon {
  padding-bottom: 5px;
}

.tooltip-component.highlight:hover {
  color: #107EFF;
}
