.single-vital-schedule-drawer {
  .vitals-monitoring-scheduling-form-component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .bg-vital {
      width: 100% !important;
      max-width: 100% !important;
    }

    .sub-title {
      display: inherit;
    }
  }
}