#problem-disease-history {
  position: relative;
  .disease-history-form-list.blur {
    .mask {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--bg-light-gray);
      opacity: 0.8;
      z-index: 2;
    }
  
    .add-another-condition-card {
      z-index: 3;
    }
  }
}

.add-another-condition-card {
  margin-bottom: 30px;

  .ant-card-body {
    padding-bottom: 40px;
  }
}