.pinned-patients-bar-component {
  min-height: 54px;
  background-color: white;
  box-shadow: 0px 0px 10px 1px var(--gray-scale-4);
  color: var(--gray-scale-1);
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;

  &__section-1 {
    padding-right: 10px;
    border-right: 1px solid var(--gray-scale-4)
  }

  &__patient-care-btn:hover {
    color: var(--ant-blue) !important;
  }

  .next-patient-button,
  .pinned-patient-wrapper {
    padding: 10px 0;
  }

  .pinned-patient-label {
    padding-left: 20px;
    margin-right: 10px;
    align-self: center;
  }

  .pinned-patient-avatars {
    overflow: auto;
    flex: 1;
    align-self: center;
  }

  .pinned-patients-component::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  .pin-patient-button,
  .recent-patient-button {
    display: flex;
    align-items: center;
    height: 100%;
    border-left: 1px solid var(--gray-scale-4);
    padding: 0 20px;
  }

  .next-patient-button {
    height: 100%;
    display: flex;
    align-items: center;
    border-left: 1px solid var(--gray-scale-4);
    min-width: 150px;
    padding: 0 20px;
  }
}
