.BPTextComponent {
  text-align: center;
  line-height: 15px;

  .first-row {
   font-weight: 600;
   font-size: 15px;
  }
  .display-or-empty {
   font-weight: 400;
   font-size: 12px;
  }
}
