.loaner-phone-unassign-modal {
  .title {
    margin-bottom: 24px;
  }

  label[for="deviceId"]::after {
    display: none !important;
  }

  .loaner-phone-form-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
    padding: 24px 0px 0px;
  }
}