.login-component {
  .anticon-question-circle {
    color: var(--gray-scale-3);
    font-size: 18px;
  }
}

.ehr-login-tooltip {
  .ant-tooltip-content {
    width: 455px;
  }

  .ant-tooltip-inner {
    padding: 16px;
  }
  
  .ant-tooltip-inner *:not(.display-or-empty) {
    text-align: left;
    padding-right: 24px;
  }
}