.pauseServiceViewComponent{
  // margin-bottom: 28px;
}

.need-reschedule-visit {
  color: var(--secondary-red);
}

.fixedComponent .fixedChildComponentWrapper {
  margin: 0 !important;
}
