.unifiedcare-app-icon-component {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px 0px #00000040;
  border-radius: var(--secondary-radius);
  padding: 10px;
  padding-right: 7px;
  width: 70px;
  height: 70px;
}
