.visit-participant-reassign-modal {
  .visit-participant-modal-content {
    padding-top: 10px;
  }

  .modal-info {
    display: flex;
    gap: 0px 10px;
    margin-bottom: 24px;
  }

  .modal-info-icon {
    font-size: 22px;
    color: var(--secondary-orange);
    margin-top: -5px;
  }

  .modal-info-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  .coworkers-tree-select,
  .ant-select-selector {
    width: 100% !important;
  }
}