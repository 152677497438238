.are-you-onsite-component {
  .not-inclinic-btn {
    color: #107EFF;
    font-weight: 500;
    letter-spacing: 0.46px;
  }

  .ant-select {
    text-align: left;
    border-radius: 4px;
  }

  &__tooltip {
    span[role="img"] {
      vertical-align: middle;
      color: var(--gray-scale-3);
    }

    svg {
      width: 16px;
    }
  }
}