.scalable-wrapper {
  position: relative;
  width: 100%;

  .scalable-content__body {
    width: 100%;
    height: 100%;
    overflow: auto;

    &.is-scalable-scaling {
      overflow: hidden;
    }
  }

  .scalable-content {
    transform-origin: 0 0;
  }

  &:not(.always-show) .scalable-control-buttons {
    display: none;
  }

  &:hover,
  &.always-show {
    .scalable-control-buttons {
      display: flex;
      gap: 0px 3px;
    }
  }

  .scalable-control-buttons {
    position: absolute;
    bottom: 25px;
    right: 25px;

    button {
      pointer-events: auto;
    }
  }
}