.group-3525{
  width: 210px;
  height: 27px;
  left: 16px;
  top: 11px;
}
  
.rectangle-4219{
  left: 16px;
  top: 12px;
  background: #FEE9E6;
  border-radius: 20px;
}
  
.group-3521{
  width: 13.84px;
  height: 13.33px;
  left: 22px;
  top: 18px;
}

.rectangle-4219 .icon{
  box-sizing: border-box;
  left: 6.45%;
  right: 89.49%;
  top: 11.39%;
  bottom: 80.17%;
  background-color: white;
  color: #DA6453;
  border-radius: 20px;
}

.text{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #DA6453;
  margin-left: 4px;
}
  