.create-visit-form {
  &__section {
    padding: 0 20px;
  }

  &__patient-search-select {
    width: 650px !important;
  }

  .employee-calendar-select-component {
    .ant-select-selector {
      border-radius: 2px !important;
      background-color: white !important;
      border: 1px solid #d9d9d9 !important;
      padding-right: 10px;
    }
  }
}
