.ant-modal-content {
  border-radius: 4px
}

.lh1 {
  line-height: 1.3;
}

.ta-l {
  text-align: left;
}

.ml20 {
  margin-left: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt20 {
  margin-top: 20px;
}

.pb20 {
  padding-bottom: 20px;
}

.pt20 {
  padding-top: 20px;
}

.fs-large-16 {
  font-size: 16px !important;
}

.fs18 {
  font-size: 18px;
}

.mt20 {
  margin-top: 20px;
}

.w200 {
  width: 200px;
}

.ml400 {
  margin-left: 400px;
}

.w405 {
  width: 405px;
}

.color-red {
  color: #e88e8f;
}

.margin-left-auto {
  margin-left: auto;
}

.button-no-focus:focus {
  outline: none;
  box-shadow: none;
}

.fs-italic {
  font-style: italic;
}

.light-grey {
  color: #E7EAED;
}
