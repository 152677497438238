.care-portal-header-component {
  padding: 12px 30px 12px 22px;
  box-shadow: 0px 1px 2px 0px #00000026;

  .header-logo {
    height: 32px;
    padding-left: 1px;

    img {
      height: 100%;
    }
  }

  .header-preview-tag .env-tag,
  .header-provider-tag {
    margin-left: 10px;
    font-size: 16px;
  }

  .header-provider-tag {
    color: #3177C9;
  }

  .header-control-button {
    width: 25px;
    height: 25px;
  }

  .patient-search-select-component .ant-select-selector {
    border-radius: 25px !important;
    border: 1px solid var(--light-gray);
    background-color: #f8f8f8;
  }

  .header-search {
    width: 454px;
  }

  .message-red-dot {

    .ant-badge-dot {
      width: 13px;
      height: 13px;
      top: 3px;
    }
  }
}

.header-popup {
  width: 1054px !important;
  min-width: 1054px !important;
  left: calc(50% - 527px) !important;
}
