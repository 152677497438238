.message-vital-duration {

  &__left-pane,
  &__right-pane {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__left-pane {
    margin-left: 6px;
  }

  &__right-pane {
    margin-left: 12px;
    padding-left: 6px;
  }

  &__title {
    font-size: 14px;
    border-bottom: 0.5px solid var(--border-gray);

    &__label {
      text-transform: capitalize;
    }

    &__date-range {
      font-weight: 600;
    }
  }

  &__text {
    border-left: 0.5px solid var(--border-gray);
    margin-top: 10px;
    font-size: 13px;
    padding-left: 6px;
    padding-bottom: 3px;
  }
}

svg#blood-drop-2tone-icon {
  .cls-1 {
    fill: #ea8585;
  }

  .cls-2 {
    fill: #af0808;
  }
}

svg#heart-2tone-icon {
  .cls-1 {
    fill: #9062be;
  }

  .cls-2 {
    fill: #611ca7;
  }
}