.message-vital-single-value__severity {
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 11px !important;

  .display-or-empty {
    color: #FFFFFF;
  }

  // bp severity colors are defined by BPMeasurementService
  &[class*="NORMAL"] {
    background: #76D376 !important;
  }

  &[class*="LOW"],
  &[class*="HIGH"] {
    background: #FDB543 !important;
  }

  &[class*="CRITICAL"] {
    background: #F0666B !important;
  }
}