.table-search-component-search-component {
  display: flex;
  flex-direction: column;
  padding: 5px;
  max-width: 800px;

  .table-search-component-search-field {
    margin-bottom: 5px;
  }

  .buttons-wrapper {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    button {
      min-width: 100px;
    }
  }

  &.relativeSelect {
    position: relative;

    .ant-select-dropdown {
      position: relative;
    }

    .table-search-component-search-field {

      & >:last-child {
        position: relative !important;
      }
    }
  }
}
