.medicationNote {
  textarea {
    background-color: #FAFBFC;
  }
  .ant-input-affix-wrapper {
    border: none;
  }

  label {
    font-size: 12px;
  }
}


.dosage-radio-group {
  display: inline-flex;
  width: 100%;
  border-color: #3177C9;

  .ant-radio-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label.ant-radio-button-wrapper {
    box-shadow: none;
    flex: 1;
  }

  label.ant-radio-button-wrapper:first-child {
    border-radius: 4px 0 0 4px;
  }

  label.ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0;
  }
}
