.techLevelLow {
  color: var(--secondary-red)
}

.techLevelMedium {
  color: var(--secondary-orange)
}

.techLevelHigh {
  color: var(--primary-green);
}
