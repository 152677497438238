.prior-auth-card-form {
  .ant-form-item-row,
  .ant-form-item-combined-rows {
    margin-top: 24px;
  }

  .ant-form-item-combined-rows .ant-form-item-row {
    margin-top: 10px;
  }

  &.white-card .white-card,
  .white-card .white-card {
    // prevent nested cards
    border: unset !important;
    background-color: #FFFFFF !important;
    padding: unset !important;
    border-radius: unset !important;
  }
}

.prior-auth-note {
  margin-top: 17px;
  width: 440px;
}