.message-upload-preview-component {
  border: 0.1px solid var(--light-gray);
  border-radius: 8px;

  .ant-badge {
    background: white;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    margin-top: 5px;
  }

  .image-preview,
  .pdf-preview__wrapper {
    width: 85px;
    height: 85px;
  }

  .pdf-preview {
    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__file-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
    }
  }
}

.message-upload-preview-modal {
  .ant-modal-body {
    padding-top: 48px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}