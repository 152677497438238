.clickable-div {

  &.disabled,
  &.disabled>* {
    cursor: not-allowed !important;
  }

  &.disabled>* {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
    background: #f5f5f5;
    pointer-events: none;
  }
}