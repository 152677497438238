.potential-clinic-tooltip-component-overlay{
  min-width: 460px;
  border-radius: var(--default-radius);

  .associated-clinic-list {
    padding: 18px 16px;

    .associated-clinic-time {
      color: var(--gray-scale-3);
    }
  }
}
