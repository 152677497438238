.cgm-device-form-item {
  .ant-form-item-row {
    .ant-form-item-label label {
      font-size: 14px;
      font-weight: 600;
      color: var(--gray-scale-1);
      width: 234px; // according to checkbox + name width
    }

    .ant-form-item-control {
      flex: 1;
    }
  }

  .ant-form-item-required::after {
    display: none !important;
  }

  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}