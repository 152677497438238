.disease-history-item-layout {
  margin-bottom: 60px;

  &__condition {
    width: 180px;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 18px;
  }

  &__info .disease-history-template-row>.ant-col {
    width: 450px;
  }

  &__info .disease-history-template-row>.ant-col:first-child {
    width: 400px;
  }

  .disease-history-template-row.disease-history-display {
    color: var(--text-darker-gray);
    font-weight: 450;

    .label {
      font-weight: 400;
      color: var(--text-gray);
    }
  }

  .disease-history-item {
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
    flex-wrap: wrap;
  }

  .disease-history-item pre {
    font-family: inherit;
  }

  .ant-input-number {
    width: 100%;
  }
}