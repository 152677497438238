.alert-item {
  min-height: 29px;
  height: auto;
  width: auto;
  border-radius: 16px;
}

.alert-item-dot {
  min-height: 8px;
  min-width: 8px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.alert-high-bg {
  background-color: #FBEAE7;
}

.alert-item-dot-high{
  background-color: var(--secondary-red);
}

.alert-item-dot-medium {
  background-color: var(--secondary-yellow);
}

.alert-item-dot-low {
  background-color: var(--primary-green);
}