.create-patient-form {
  .section-title-component {
    border-bottom: 0.2px solid var(--gray-scale-4);
  }


  .ant-modal-mask,
  .ant-modal-wrap {
    position: absolute;
  }
}

.patient-create-cancel__insurance-prompt {
  .ant-modal-confirm-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .ant-modal-confirm-btns {
    display: none;
  }
}