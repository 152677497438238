.search-dropdown {
  --search-dropdown-width: 100%;
  --search-dropdown-height: 42px;
  width: var(--search-dropdown-width);
  height: 100%;


  &__input,
  &__select {
    width: var(--search-dropdown-width);
  }

  &__input {
    height: var(--search-dropdown-height);
  }

  .ant-input-prefix {
    margin-right: 10px;
  }

  .search-dropdown__select {

    >.ant-select,
    .ant-select-selector {
      width: var(--search-dropdown-width);
    }

    &.search-dropdown__hide {
      height: var(--search-dropdown-height);
      margin-top: calc(-1 * var(--search-dropdown-height));

      >.ant-select,
      .ant-select-selector {

        height: var(--search-dropdown-height);
        visibility: hidden;
        padding: 0px;
        border: none;
      }
    }
  }

  &__popup {
    .anticon.anticon-check {
      display: none !important;
    }

    .pagination-select-option {
      padding-top: 5px;
    }
  }
}

.search-dropdown-menu {
  transform: translateY(-80px);
}

.dropdown-search-categories .ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content {
  display: none;
}

.dropdown-search-categories {
  .ant-tabs-tab {
    border: none;
    font-size: 0.8em;
    margin-right: 5px;
    padding: 8px 10px;
  }

  div.ant-tabs-tab:first-child {
    border: none !important;
  }
}