.cgm-device-brand-confirm {
  .ant-popover-inner {
    background-color: #595959;
    width: 393px;
    padding-bottom: 10px;
  }

  .cgm-device-brand-confirm__content,
  .ant-checkbox-wrapper {
    color: #FFFFFF;
  }
}