.simplified-worklist {
  .worklist-section {
    padding-bottom: 60px;
  }

  .simplified-worklist-section {
    margin-top: 20px;
  }

  .simplified-worklist-section {
    .patient-common-card-component-wrapper>div {
      margin-bottom: 5px;
    }

    .patient-common-card-last-time-update {
      margin-top: -12px;
    }
  }

  .simplified-worklist-section .worklist-section-header {
    margin-top: 12px;
    margin-bottom: 16px;
  }

  .simplified-worklist-section .patient-common-card-component-wrapper {
    margin-bottom: 24px;
  }

  .mtpr-wrapper {
    margin-bottom: 24px;
  }
}