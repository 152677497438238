.HSTableComponent {
  tr {
    cursor: pointer;
  }

  tr.isNested {
    background-color: var(--gray-scale-5);
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td{
    border-right: none;
  }

  .ant-table.ant-table-middle {
    border-left: none;
    overflow: hidden;
  }
}
