$prior-auth-card-wrapper-width: max(693px, 50%);
$prior-auth-card-wrapper-padding-right: 50px;

.prior-auth-form-item {
  .prior-auth-card-wrapper {
    max-width: $prior-auth-card-wrapper-width;
    padding-left: 24px;
    padding-right: $prior-auth-card-wrapper-padding-right;

    .ant-form-item-control-input-content>.ant-picker,
    .ant-form-item-control-input-content>.ant-select,
    .ant-form-item-control-input-content>.ant-input {
      width: 309px;
    }

    #priorAuthDeniedReason {
      width: 100%;
    }
  }
}