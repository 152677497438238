.ant-drawer.extendable-drawer {

  .ant-drawer-mask {
    height: 100vh;
  }

  .ant-drawer-content-wrapper {
    box-shadow: unset;
  }
}

.extendable-drawer-scrolling-layer {
  // allow scrolling for extendable drawer
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  .ant-drawer.extendable-drawer-root {
    width: max(100vw, var(--total-width)) !important;
  }

  .ant-drawer.extendable-drawer {
    position: absolute;
  }
}