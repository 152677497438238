.consent-form-status-bar {
  &.signed-consent {
    background-color: #E9FAF0;
  }
  &.updated-consent {
    background-color: #FFF4E8;
  }
  &.pending-consent {
    background-color: #FFF9DF;
  }
}