.message-article-item-component {
  .ant-card-cover {
    display: flex;
    justify-content: center;
  }

  img {
    height: 200px;
    width: auto;
    max-width: 100%;
  }
}