.medical-alert-dashboard-table {
  .alert-tag {
    padding: 6px 0px;
    margin: 1px 0px;
  }
}

.alert-type-select {
  .ant-select-selector {
    padding: 0px 11px;
  }
}