.start-now-button-popover {
  .ant-popover-inner-content {
    width: 375px;
    padding: 24px;
  }

  .start-now-create-visit-form-buttons {
    flex-direction: row-reverse;
    justify-content: flex-start !important;
  }
}
