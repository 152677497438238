.waystar-result-modal {
  .ant-modal-body {
    padding: 0px !important;
  }

  .waystar-result-modal__content {
    padding: 24px;

    .anticon.anticon-exclamation-circle {
      color: var(--secondary-red);
    }
  }

  .waystar-result-modal__footer {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--gray-scale-4);
    padding: 24px;
  }
}