.clickable-patient-name-btn {
  padding: 0px;

  &.ant-btn-text:hover {
    background: none;
  }

  span {
    color: var(--uc-blue);
    font-weight: 400;
  }
}
