.table-filter-component-filter-component {
  display: flex;
  flex-direction: column;
  padding: 5px;

  .table-filter-component-filter-field {
    margin-bottom: 5px;
  }

  .buttons-wrapper {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    border-top: 1px solid #f0f0f0;
    padding: 7px 8px 2px;

    button {
      padding: 0 7px;
      height:auto;
    }
  }
}
