.createEditTicketForm {

  .coworkers-tree-select {
    &.ant-select.ant-select-in-form-item {
      width: 100%;
    }
  }
  .ticketTitle {
    font-weight: 600;
    font-size: 20px;
    color: var(--gray);
  }

  &__patient-search-select {
    width: 450px !important;
  }
}
