.behavior-change-tooltip-component {
  padding: 26px;
  
  &__table,
  &__table-cell {
    border: 1px solid var(--Neutral-5, #D9D9D9);
  }

  &__table {
    border-radius: 6px;
    background: var(--Neutral-2, #FAFAFA);
  }
}
