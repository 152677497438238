.prior-auth-confirm-content {
  width: 450px;
  padding: 18px 24px 58px 32px;

  .prior-auth-confirm-buttons {
    .ant-btn {
      height: 32px;
      min-width: 100px;
    }
  }
}

.prior-auth-confirm-modal {
  .ant-modal-body {
    padding: 24px 24px 12px 12px;
  }
}