.rewrite-with-ai-component {
  margin-right: 0px;
  max-height: 700px;
  overflow-y: auto;

  .divider {
    margin: 10px 0;
  }

  .check-mark svg {
    color: var(--ant-blue);
    opacity: 0.4;
  }

  .ant-btn-primary:not([disabled]) {
    color: #fff;
    border-color: #1890FF;
    background: #1890FF;

  }
}
