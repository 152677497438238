.article-select-option {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .article-select-option-info,
  .article-select-option-icon {
    padding: 3px 0px;
  }
  

  .article-select-option-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2px;

    .ant-tag {
      border: none;
      margin: 0;
    }

    .core-concept-tag {
      background-color: #FFF4E8;
      color: var(--secondary-orange);
    }

    .new-video-tag {
      display: inline-flex;
      align-items: center;
      gap: 5px;
      background-color: var(--uc-blue);
      color: #FFFFFF;
    }

    .base-icon-container {
      height: 22px;
    }
  }

  .article-select-option-icon i {
    display: none;
  }

  .article-select-option-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__last-sent {
      text-align: right;
      
      span[role="img"] {
        margin-left: 5px;
      }
    }
  }

  &:hover .article-select-option-icon {
    &__last-sent {
      font-style: italic;
    }

    i {
      display: inline-block;
      margin-left: 8px;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    display: table;
  }
}

[class*="__link"] {
  color: var(--gray-scale-1);
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.search-dropdown__popup .ant-select-item.ant-select-item-option:hover {
  background-color: rgba(24, 144, 255, 0.1);
}