.control-level-table {
  width: 100%;
  border-collapse: separate; /* Allows for border radius */
  border-spacing: 0; /* Remove spacing between cells */
  margin: 20px 0;
  background: white;
  // border: 1px solid #ccc; /* Outer border */
  border-radius: 6px; /* Rounded corners for the table */
  
  /* Add border for individual table cells */
  td {
    padding: 10px;
    border: 1px solid #ddd; /* Reintroduce inner borders between cells */
  }

  tr:first-child td {
    border: 1px solid #ddd; 
  }

  tr td:last-child {
    border-left: 0;
  }

  tr td {
    border-top: 0; /* Remove top border for the first row */
  }

  /* Add border-radius to the top-left and top-right corners */
  tr:first-child td:first-child {
    border-top-left-radius: 6px;
  }

  tr:first-child td:last-child {
    border-top-right-radius: 6px;
  }

  /* Add border-radius to the bottom-left and bottom-right corners */
  tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
  }

  /* Badge column for consistent width */
  .badge-column {
    width: 160px; /* Set a fixed width for the badge column */
  }

  ul {
    padding-left: 5px;
    margin-bottom: 0;
  }

  li {
    margin-left: 10px;
  }
}


