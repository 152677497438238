.error-simulator {
  position: fixed;
  top: 50px;
  left: 0%;
  width: 600px;
  background-color: #FFFFFF;
  border: 1px solid var(--border-gray);
  z-index: 1003;

  .minimizable-control,
  .minimizable-children {
    padding: 20px;
  }

  .minimizable-control {
    border-bottom: 1px solid var(--border-gray);
  }

  .minimizable-children {
    min-height: 400px;
  }

  .error-boundary-info-text {
    margin-top: 8px;
    max-height: 500px;
    overflow: auto;
  }
}

div#webpack-dev-server-client-overlay-div,
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}