.error-boundary-card-component {
  border: 1px solid var(--gray-scale-4);
  padding: 20px;
  border-radius: var(--default-radius);

  .error-boundary-card-component-content {
    color: var(--gray-scale-1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
  }
}
