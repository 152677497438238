.patients-radio-card-component {
  border-radius: 8px;
  border: 1px solid var(--Grayscale-4, #E7EAED);
  background: var(--Grayscale-5, #F4F6F8);
  padding: 24px;
  width: 380px;
  height: 280px;

  .ant-card-body {
    padding: 0px 0px;
  }

  .ant-radio-wrapper {
    margin: 0px 0px;
  }
}

.render-check-mark {
  color: gray;
}

.render-card-footer {
  border-top-color: var(--Grayscale-1, #B5B9BE);
}

.render-card-footer-text {
  margin-top: 12px;
  color: var(--Grayscale-2, #6B7178);
  font-family: Noto Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.render-patient-title {
  color: var(--Grayscale-1, #2D3238);
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}