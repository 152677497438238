#medicationFormBody {
  padding: 16px;
  label {
   font-size: 14px;
   color: rgba(0,0,0,.65);
  }

  label[for="medicationCompliance"],
  label[for="medicationReminder"] {
    width: 100%;
  }
}

.medication-list-note {
  resize: auto !important;
}
