.billable-time-review-table-component {
  .ant-table-thead > tr > th {
    background-color: var(--light-gray);
    border-bottom: 1px solid var(--light-gray);
    border-top: 1px solid var(--light-gray);
    padding: 12px 18px;
    text-align: left;
  }

  .enter-button {
    background: var(--uc-blue-secondary) !important;
  }
}