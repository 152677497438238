.pull-up-drawer-wrapper-component {
  position: relative;
  overflow: hidden;
  height: 100%;

  .pull-up-drawer-component {
    .ant-drawer-content,
    .ant-drawer-content-wrapper,
    .ant-drawer-wrapper-body,
    .ant-drawer-header {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }
  }
}
