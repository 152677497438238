$marginBottom: 10px;

.add-memo-button,
.add-memo-button:hover,
.add-memo-button:focus {
  background-color: #FEF4D6;
  color: var(--gray-scale-2);
  border: none;
  margin-bottom: $marginBottom;
}

.memo-component {
  position: relative;
  height: 32px;
  margin-bottom: $marginBottom;
}
