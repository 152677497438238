.transcribe-form-fixed-bottom {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: white;
    z-index: 2;
    padding-bottom: 24px;
    box-shadow: 0px -5px 8px 0px #00000008;
  }

  form#transcribeNote {
    position: sticky;
    bottom: 0px;
    background-color: #FFF;
    padding: 12px var(--horizontal-padding);
    border-top: 1px solid var(--border-gray);
    box-shadow: 0px -5px 8px 0px #00000008;

    label {
      // label for note input
      display: none;
  }
}

.transcribe-form-divider {
    border: none;
    margin: 0px;
  }

.transcribe-fix-btn .ant-drawer-body{
  padding: 0 24px;
  padding-top: 12px;
}

.transcribe-fix-btn .ant-card-body{
  padding: 0 24px;
}

.transcribing-visit__medication {
  .ant-card-body {
    padding: 0px;
  }
}