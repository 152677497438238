.cgm-chart {
  .threshold-label {
    min-width: 30px;
    border: 1px solid transparent;
    border-radius: 2px;
    z-index: 2;

    color: #FFFFFF;
    text-align: center;
    font-weight: 500;
    // move to center of line
    margin-top: 8px;
    margin-left: 5px;
  }

  .cgm-chart-tooltip-body {
    padding: 10px;
    padding-right: 18px;
    background: #FFFFFF;
    border: 0.5px solid var(--gray-scale-4);
    border-radius: 4px;

    .cgm-data-time {
      font-size: 12px;
    }

    .cgm-data-value {
      font-size: 16px;
      font-weight: 700;
      color: #000000;
    }
  }

  &.cgm-chart-compact .highcharts-graph {
    stroke-width: 1px;
  }

  &:not(.cgm-chart-compact) .highcharts-graph {
    stroke-width: 2px;
  }
}