.mini-calendar-component {
  .calendar-title-component {
    margin-bottom: 10px;
    padding: 0 20px;
    justify-content: space-between;
    width: 100%;
  }

  .ant-picker-panel {
    border: none;
  }

  &, .ant-picker-panel {
    background: transparent !important;
  }
}
