$overlay: 200;

$call-center-plane: 2000;
$call-log: 2001;
$call-center-control-panel: 2002;
$call-center-plane-draggable: 2002;

$zoom-video: 2003;

// mainly to make memo component on the top(ticket NUC-1183)
$ant-tabs-nav: 10; 

$ant-modal: 1040;

:export {
  modal: $ant-modal;
}
