.visit-summary-input-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--secondary-red);
}

.visit-summary-input-component {
  margin-bottom: 5px;
  
  &, &:hover, &:active {
    border: unset;
  }

  &.required,
  &.required:hover,
  &.required:active {
    border: 1.5px solid var(--secondary-red) !important;
    box-shadow: unset;
  }
}

.input-area-with-ai-button {
  padding-bottom: 30px;
}
