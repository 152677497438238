.login-header-component {
  margin-bottom: 30px;

  .title {
    font-size: 24px;
  }

  .title,
  .sub-title {
    text-align: center;
  }
}