.date-navigator {
  display: flex;
  gap: 5px;
  align-items: center;

  .date-navigator__prev {
    margin-right: 10px;
  }

  .date-navigator__next {
    margin-left: 10px;
  }
}