.llm-transcribe-labresult {


  .llm-missing-error {
    // refer to MISSING_ERROR_CLSNAME
    // trick to scroll and focus the error input
    scroll-margin-top: 45px;
  }
}

.llm-lab-result-upload-guide--tooltip {
  width: 450px;
  height: 264px;
}
