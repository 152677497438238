.mini-user-component {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 40px;

  .mini-user-component-email {
    font-size: 12px;
    color: var(--secondary-gray);
  }
}
