@use '../../styles/zIndex.scss';

.overlay-container {
  .show-overlay {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    opacity: 0.8;
    z-index: zIndex.$overlay;
  }

  .overlay-content {
    opacity: 1;
  }
}

.overlay-container,
.child-component {
  height: 100%;
  width: 100%;
}
