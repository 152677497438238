.prev-next-buttons-component {
  .prev-btn, .next-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .prev-btn:hover, .next-btn:hover {
    background-color: var(--light-gray);
  }
}
