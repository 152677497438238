.app-info-logo-container {
  width: 60px;
  height: 60px;
  border: 2px solid var(--gray-scale-5);
  border-radius: 5px;
}

.app-info-logo {
  width: 37px;
  height: 50px;
}

.app-info-tag {
  margin-left: -11px;
}

.app-info {
  .title-content-text-title {
    color: var(--secondary-gray);
  }
}
