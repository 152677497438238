.cpt-code-units {
  padding: 10px 0px 30px 15px;

  .ant-input-number {
    width: 60px;
  }

  .cpt-code-units-value {
    width: 45px;
  }

  &.readOnly {
    padding-left: 0px;
    padding-top: 0px;
    padding-right: 0px;

    .cpt-code-units-row {
      margin-top: 5px !important;
      gap: 0px !important;
    }
  }
}