.message-text {
  overflow-wrap: anywhere;
  border-top-left-radius: 0;
  color: var(--text-gray-primary);
}
.message-item__message-primary{
  ::selection{
    background: rgb(78, 230, 247);
    color: black;
  }
}