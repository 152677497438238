.message-history-component {
  background: var(--background-gray-a03);
  min-height: 100%;
  position: relative;
  padding-bottom: 14px;

  .message-article-item-component {
    border-radius: 10px;
    padding-bottom: 10px;

    .ant-card:hover {
      box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.3)
    }

    .ant-card-meta-title {
      font-size: 14px;
      white-space: pre-wrap;
    }
  }

  .message-history-bottom {
    clear: both;
    height: 1px;
    position: absolute;
    bottom: 0;
  }

  .message-sender-name {
    font-size: 12px;
  }
}

.message-history-empty-component {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-left: 16px;
  line-height: 32px;
  color: var(--secondary-gray);
  background: var(--background-gray-a03);
}

.message-ai {
  background: var(--background-gray-a03);
  padding-left: 20px;
  padding-right: 20px;

  .ant-collapse,
  .ant-collapse-icon-position-start {
    background-color: rgb(233, 241, 250) !important;

    border: none !important;
    border-bottom: none !important;

    border-radius: 10px;
  }

  .ant-collapse-content {
    border-top: none !important;
    background-color: rgb(233, 241, 250) !important;

    border-radius: 10px;
  }

  .ant-collapse>.ant-collapse-item {
    border-bottom: none !important;
  }

  .ant-collapse-item:last-child>.ant-collapse-content {
    border-radius: 10px !important;
  }

  .ant-collapse-arrow {
    color: var(--primary-blue-6) !important;
  }
    
}
