.cgm-device-checkbox-component {

  .ant-collapse-header,
  .ant-collapse-content-box {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
  }

  .ant-collapse-content-box {
    padding-right: 0px !important;
  }

  .device-checkbox-component {
    border: unset !important;
    margin-top: 0px;
  }

  .cgm-device-form-item {
    padding: 20px;
    border-radius: 0 0 8px 8px;
    border: 1px solid var(--border-gray);
    border-top: none;
    background: var(--White, #FFF);

    .ant-form-item-row {
      flex-direction: row;
      padding-top: 26px;
      padding-bottom: 26px;
      border-bottom: 1px solid var(--border-gray);
    }

    .ant-form-item:last-child .ant-form-item-row {
      border-bottom: none;
    }
  }

  .cgm-device-checkbox-component__device-status {
    display: flex;

    .missing-device-selection {
      margin-right: 8px;
    }

    .missing-icon {
      font-size: 16px;
      color: #DA6453;
      margin-right: 4px;
    }

    .missing-text {
      font-size: 14px;
      color: #DA6453;
    }
  }
}

.cgm-device-checkbox-component__collapse {
  background: #FFFFFF;

  .ant-collapse-item {
    border-bottom: unset;
  }

  .ant-collapse-header {
    color: #FFFFFF;
  }

  .ant-collapse-header-text {
    padding: 20px 0px;
  }

  .ant-collapse-header-text .clickable-div {
    height: 100px;
  }
}

.cgm-device-checkbox-component__collapse,
.ant-collapse-item {
  border-radius: 8px;
}

.cgm-device-checkbox-component__collapse--selected {
  background: var(--gray-scale-7);
}
