.HSLogBookDateComponent {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 0;

  .hs-count {
    background-color: var(--light-gray);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    min-width: 20px;
    text-align: center;
    line-height: 20px;
    font-weight: 500;
  }

  &.nested {
    padding-left: 24px;
  }
}
