.chronic-diagnoses-note {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 14px 0px;
  justify-content: flex-start;
  align-items: center;

  .ant-btn-link {
    padding: 0;

    span {
      color: var(--text-gray);
      text-decoration: underline;
    }
  }
}