.historyDrawerComponent {
 .ant-timeline-item.grey .ant-timeline-item-head{
  background-color: var(--gray-scale-3);
  border-color:var(--gray-scale-3);
  color:var(--gray-scale-3);
 }
 .ant-timeline-item.blue .ant-timeline-item-head{
  background-color: var(--blue);
 }

 div.historyTime {
  color: #000;
 }
}
