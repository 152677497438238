@use '../../../../styles/zIndex.scss';

.zoom-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  // should always load this on top of everything else
  z-index: zIndex.$zoom-video;

  &.loading {
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: unset;

    .zoom-loader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
    }

    .zoom-loader-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      padding: 24px;
      padding-bottom: 42px;
      background-color: #242424; // zoom background color
      border-radius: var(--default-radius);
      color: #FFF;
      font-size: 14px;
      font-weight: 800;
    }
  }

  #meetingSDKElement>* {
    pointer-events: all;
  }

  // to bound draggable component (1)
  .zoom-video-draggable-component,
  #meetingSDKElement {
    width: fit-content !important;
  }

  // to bound draggable component (2)
  #meetingSDKElement>div {
    position: relative !important;
  }

  // prevent color override
  .zoom-MuiPaper-root {
    color: #f5f5f5
  }
}

// fix color in zoom
.zoom-video,
[role="dialog"][class^="zoom"] {
  h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }
}

// caption popup
[role="dialog"][x-placement*="right-start"],
// caption popup
[role="dialog"][x-placement*="left-start"],
// zoom dialogs, popup
[role="dialog"][class^="zoom"],
[class^="zoom-MuiPopper-root"] {
  position: fixed !important;
  z-index: zIndex.$zoom-video !important;
}

// custom buttons
:not([role*="dialog"]),
:not([role*="tooltip"]) {

  &[x-placement*="top"],
  &[x-placement*="bottom"] {
    // more button
    position: fixed !important;
    z-index: calc(zIndex.$zoom-video + 1) !important;
  }
}

// error, warning popup
[role="presentation"][class^="zoom"] {
  display: none;
}

// draggable handle, get classname in ZoomVideoComponent
[class*="zoom-MuiToolbar-root"]>div {
  cursor: move;
}

.zoom-video #suspension-view-tabpanel-active:not([hidden])>div {
  max-height: var(--zoom-max-height) !important;
}