.checkbox-card {
  padding: 24px;
  border: 1px solid var(--gray-scale-4);
  border-radius: var(--default-radius);

  &.checkbox-only {
    padding: 0px;
    padding-left: 24px;
    pointer-events: none;

    .ant-checkbox {
      pointer-events: auto;
    }

    .checkbox-children {
      padding: 18px 8px;
      padding-right: 24px;
      pointer-events: auto;
      cursor: auto;
    }
  }
}