.section-title-component {
  position: relative;

  .section-title {
    font-size: 14px;
    line-height: 19px;
    display: inline-block;
    margin: 0;
    border-bottom: 3px solid black;
    padding-bottom: 2px;
  }

  hr {
    border: none;
    margin: 0;
    height: 1px;  
    background: #E7EAED;
    width: 100%;
    position: absolute;
    bottom: 1px;
    z-index: -1;
  }
}