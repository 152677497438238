.outstanding-drawer-button {
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: #fee9e6;
    color: var(--secondary-red);
    height: 32px;
    border-radius: 18px;

    // width for max content: 'Outstanding Items 99'
    width: 180px;
  }

  .ant-typography {
    color: var(--secondary-red);
  }
}
