.bp-clear-checkbox {
  .device-info {
    display: flex;
    flex-direction: column;
  }
  .device-info h3 {
    height: 24px;
  }
  .support-device-info {
    align-self: center;
    margin-left: -25px;
  }
}