.message-vital-weight-change {
  font-size: 12px;
  margin-top: 8px;
  gap: 2px; 
}

.weight-change {
  &__no-change {
    color: var(--uc-blue);
  }

  &__up {
    color: var(--secondary-red);
  }

  &__down {
    color: var(--secondary-green);
  }
}