.AnnouncementItemComponent {
  padding-left: 24px;
  padding-right: 24px;

  .announcement-type-icon {
    border-radius: 50%;
    background: #fff;
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .anticon {
    font-size: 20px;
  }

}
