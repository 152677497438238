.ant-select-selector {
  max-width: 100%;
}

.employeeSearch {

  max-width: 352px;

  .ant-select-tree-switcher {
    width: 0;
  }

  &.ant-tree-select-dropdown {
    padding: 8px 0;
  }

  .ant-select-tree-treenode {
    border: 1px solid var(--gray-scale-5);
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper {
    padding: 5px 12px;
  }
}
