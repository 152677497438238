.SymptomTableComponent {
  .symptom-count {
    background-color: var(--blue-tint);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-weight: 500;
    margin-left: 3px;
  }
}
