.preview-env-warning-modal {
  .env-tag {
    font-size: 56px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 56px;
    padding: 10px 22px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 439px;
  }

  .title {
    font-size: 24px;
  }

  .sub-title {
    font-size: 16px;
  }

  .ant-btn-primary {
    margin-top: 40px;
  }
}