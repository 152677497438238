.device-section-common {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 25px;
  padding-top: 5px;

  .cgm-device-section__label {
    font-size: 14px;
    font-weight: 500;
    flex-shrink: 0;
    width: 250px;
  }

  .cgm-device-section__value {
    flex-grow: 1;
    .ant-radio-group {
      display: flex;
      gap: 20px;
    }
  }
}

.account-linking {
  padding-top: 25px;
  border-top: 1px solid var(--border-gray);

  .unlink-account-button {
    color: #2D3238;
    font-weight: 500;
    span {
      text-decoration: underline;
    }
  }

  .link-account-button {
    border-color: #2D3238
  }

  .custom-ok-button {
    background-color: #107EFF;
    background: #107EFF;
    border-color: #107EFF;
    color: #fff;
  }
}


.device-section {
  @extend .device-section-common;
}

.device-section-linking {
  @extend .device-section-common;
  align-items: baseline;
}

.toggle-icon {
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.3s;
  color: #2D3238;

  &.rotate {
    transform: rotate(180deg);
  }
}
