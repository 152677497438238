.base-icon-container,
.base-svg-icon-container {
  width: max-content;
  display: inline-block;
}

.base-icon-button {
  all: unset;
  cursor: pointer;
}

.base-svg-icon {
  vertical-align: middle;
  width: 100%;
  height: 100%;

  &.fill-color path {
    fill: currentColor;
  }

  &.fill-color circle {
    stroke: currentColor;
  }
}

