.llm-drawer-labresult {
  .ant-drawer-close {
    margin-right: 0px;
  }

  .ant-drawer-body {
    position: relative;
    padding-top: 12px;
    padding-bottom: 0px;
  }

  .ant-table-wrapper {
    margin-bottom: 8px;
  }
}

#llm-drawer-labresult__body {
  padding-top: 12px;
}