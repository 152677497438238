.message-input-component {
  background-color: white;
  padding: 20px;

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .ant-btn {
      width: auto;
      padding: 5px;
    }

    .mr-12 {
      margin-right: 12px;
    }

    .anticon {
      font-size: 21px;
    }

    svg {
      opacity: 0.5;
    }
  }

  &__actions__upload-preview {
    opacity: 1;
  }

  &__actions__attach-article {
    margin-top: -5px;

    button {
      border: none;
      height: auto;
    }
  }

  .message-file {
    cursor: pointer;
  }

  .message-send-btn {
    padding: 5px 20px;
    margin-left: 8px;

    &:not(:disabled) {
      background-color: var(--primary-blue-6);
      border-color: var(--primary-blue-6);

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
