.ant-select.select-with-prefix-icon-component {
  &.full-width {
    width: 100%;
  }

  .ant-select-arrow {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
  }

  .ant-select-arrow * {
    cursor: inherit;
    pointer-events: none !important;
  }

  &.show-suffix-icon .ant-select-selection-search,
  &.show-suffix-icon .ant-select-selection-placeholder,
  &.show-suffix-icon .ant-select-selection-item {
    margin-right: 1em;
  }

  &.show-prefix-icon .ant-select-selection-search,
  &.show-prefix-icon .ant-select-selection-placeholder,
  &.show-prefix-icon .ant-select-selection-item {
    margin-left: 1.5em;
  }

  &.ant-select-single.ant-select-sm .select-with-prefix-icon-component__prefix-icon {
    width: 1em;
  }

  .ant-select-selection-search input {
    font-size: 14px;
  }
}