.complexity-tag {
  background-color: #E7EAED;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-right: 0;
}

.non-complex-tag {
  .tag {
    color: var(--gray-scale-1) !important;
  }
}
