.worklist-drawer-component {
  .ant-drawer-body {
    background-color: #FAFAFA;
    padding: 0px;
  }

  .steps-component.ant-steps-vertical.ant-steps-small .ant-steps-item-container {
    padding: 12px 0px;
  }

  .worklist-drawer-content>.fixedChildComponentWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .worklist-drawer-content .visit-summary-input-wrapper {
    flex: 1;
    display: flex;
    align-items: flex-end;
  }

  .worklist-navigation {
    position: sticky;
    top: 0px;
    right: 0px;
    height: 100vh;
    width: 400px;
    padding: 24px;
  }

  .worklist-section {
    flex: 1;
    background-color: #FFFFFF;
    padding: 24px;
    height: 100vh;
    overflow-y: auto;
  }
}

$min-width: 1330px;

@media only screen and (min-width: $min-width) {
  .worklist-drawer-component .ant-drawer-content-wrapper {
    width: $min-width !important;
  }
}

@media only screen and (max-width: $min-width) {
  .worklist-drawer-component .ant-drawer-content-wrapper {
    width: 100vw !important;
  }
}