.unicare-copilot-component {
  right: 20px;
  margin-right: 20px;
  margin-top: 30px;

  .ant-modal-body {
    padding: 0px;
    height: 628px;
    padding: 0px 0px;
  }

  .ant-modal-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0px 0px;
  }

  .ant-modal-mask {
    background: transparent;
  }

  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-wrap {
    left: auto;
  }

  .ant-modal-content {
    .expand-button {
      height: 55px;
      border: none;
      background: none;
      color: white;
      position: absolute;
      right: 85px;
      font-size: 24px;
    }

    .ant-modal-close-x {
      color: white;
      font-size: 24px;
    }
  }
}

.iframe-border {
  border-radius: 16px;
  border: 0;
}