.a1c-due-halfFilled {
  color: var(--secondary-red);

  .tag-text-color {
   color: var(--secondary-red);
  }
};

.medical-history {
  margin-left: auto;
}

.addLabResult.extendable-drawer {
  .ant-drawer-body {
    overflow-y: hidden;
  }
}
