.drawerComponent {
  .ant-modal-header, .ant-modal-footer {
    border: none;
    padding: 16px 24px 0px;
  }
  .ant-modal-body {
    padding: 0 24px 16px;
  }
  .addMedicationModalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #medicationObjectForm {
    .ant-form-item-label {
      padding-bottom: 0;
    }
    .ant-form-item {
      margin-bottom: 10px;
    }

    button {
      margin: 0 auto;
    }

    label.ant-checkbox-wrapper {
      font-weight: normal;
      color: var(--text-gray);
    }

  }
}
