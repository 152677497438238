.mtpr-wrapper {
  box-shadow: 0px 0px 16px 0px #00000014;
  border: 1px solid #E7EAED;
  padding: 15px;

  .mtpr-note {
    .ant-form-item-label>label::after {
      // hide required mark
      display: none !important;
    }
  }

  .missing-warning {
    .mtpr-note {

      textarea#mtprNote_note,
      textarea#mtprNote_note:hover,
      textarea#mtprNote_note:focus {
        border: 1px solid var(--secondary-red);
        box-shadow: none;
      }
    }

    // hide error text from form to prevent duplicate show
    #mtprNote .ant-form-item-has-error .ant-form-item-control>div:not(.ant-form-item-control-input) {
      display: none !important;
    }

    .missing-error-wrapper {
      margin-top: -10px;
    }
  }

  #clinicalGoalForm .clinical-goal-list {
    border-top: var(--mtpr-border-style);
  }
}