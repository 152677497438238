.vital-summary-component {
  display: flex;
  margin-bottom: 20px;

  &__item {
    font-size: 16px;

    .item-title {
      font-weight: 400;
      font-size: 13px;
    }

    .item-value {
      font-weight: 700;
      font-size: 16px;
    }

    .item-extra {
      font-size: 13px;
      color: var(--gray-scale-2);
    }
  }
}

.hs-variability-tooltip {
  .ant-tooltip-inner {
    background-color: var(--gray-scale-2);
  }
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: var(--gray-scale-2);
  }
}
