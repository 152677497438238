.transcribing-patient-info-card-component {
  background: var(--neutral-2, #FAFAFA);
  border-radius: var(--secondary-radius);
  padding: 16px 20px 18px 20px;

  .ant-card {
    background-color: transparent;
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-row {
    margin-bottom: 0;
  }
}
