.text-with-sub {
  font-weight: 400;

  .main-text {
    font-size: 14px;
    &.with-link{
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .sub-text {
    color: var(--secondary-gray);
    font-size: 12px;
  }
}
