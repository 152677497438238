.mtpr-complete {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 12px 10px;

  .tooltip-component .anticon {
    color: var(--gray-scale-3);
  }
}

.mtpr-complete,
.mtpr-complete .updated-by-at-component {
  color: #000;
}