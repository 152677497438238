.onsite-info {
  cursor: default;
  
  .base-icon-container {
    width: 23px;
    height: 20px;

    svg path {
      fill: #FFFFFF;
      stroke: #000000;
      stroke-width: 2px;
    }
  }

  &__title {
    font-size: 12px;
    font-weight: 400;
    color: var(--text-gray);
  }
}

.onsite-info__modal {
  .ant-modal-body {
    padding: 51px 32px;
  }

  .ant-btn.ant-btn-primary {
    margin-top: 32px;
  }
}