.print-care-plan-preview {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 38px;

  & * {
    font-size: 12px;
  }

  ul {
    padding-left: 20px;
    padding-bottom: 0px;
  }

  .pop-text,
  .pop-text>* {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 700;
    margin-top: 15px;
  }

  .print-care-plan-preview-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 22px;
    }
  }

  .print-care-plan-preview-profile {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .print-care-plan-preview-profile__details {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 24px;
  }

  .print-care-plan-preview__divider {
    margin-top: 12px;
    margin-bottom: 12px;
    border-width: 2px;
  }

  .ant-divider:not(.print-care-plan-preview__divider) {
    visibility: hidden;
    margin: 5px 0px;
  }

  .bold-divider {
    border-width: 4px;
    border-color: var(--gray-scale-3);
  }

  .print-care-plan-preview-program-description {
    color: var(--gray-scale-2);
    font-style: italic;
    line-height: 18px;
    margin-bottom: 16px;

    .program-description__title {
      font-weight: 600;
      margin-right: 5px;
    }
  }

  .print-care-plan-preview-care-team {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .print-care-plan-preview-care-team__names {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px 20px;
    }
  }

  .print-care-plan-preview-reminder {
    display: flex;
    gap: 12px;
    background-color: var(--gray-scale-5);
    border-radius: var(--default-border-radius);
    padding: 14px;
    margin-top: 12px;
    margin-bottom: 12px;

    img {
      margin-top: 4px;
    }
  }

  .print-care-plan-preview-disease-history {
    .b5 {
      font-weight: bold;
    }
  }

  .print-care-plan-preview-behavioral-goal {
    .mb10 .card-text-component>div {
      color: #000000 !important;
    }
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
}

@media print {
  .page-break {
    margin-top: 0.5em;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 15mm 0;
}
