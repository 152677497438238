.message-vital-single {
  padding-bottom: 6px;
  background: #FFFFFF;

  &__title {
    font-size: 12px;
    padding-bottom: 6px;
    border-bottom: 0.5px solid var(--border-gray);
    display: flex;
    gap: 0px 3px;
    justify-content: center;
  }

  &__info {
    padding: 2px;
    width: 58%;
  }

  &__text {
    font-size: 13px;
    padding: 6px;
    border-left: 0.5px solid var(--border-gray);
  }
}