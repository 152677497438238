.insurance-eligibility-history {
  .ant-tabs-extra-content {
    margin-bottom: 25px;
    width: 100%;
    text-align: left;
    font-size: 13px;
  }

  .ant-tabs-tab {
    padding-left: 0px !important;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
    margin: 0px !important;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
  }

  .ant-tabs-nav {
    color: var(--gray-scale-2);
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    color: var(--gray-scale-3);
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--gray-scale-1);
  }
}