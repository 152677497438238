.employee-calendar-select-component {
  width: 100%;
  
  .ant-select-selector {
    border-radius: var(--secondary-radius);
    background-color: #EDEDED !important;
    min-height: 40px;
    line-height: 36px;
    border: unset !important;
    padding-left: 30px;
  }
}

.employee-calendar-popup {
  border-radius: var(--secondary-radius);

  .ant-select-item {
    display: flex;
    align-items: center;
  }

  .ant-select-item-option {
    border: 1px solid var(--gray-scale-5)
  }
}
