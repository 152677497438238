.big-calendar-wrapper {
  position: relative;
  height: calc(100vh - 73px);

  .big-calendar-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: #FFFFFF90;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .big-calendar {
    .rbc-toolbar {
      
      .rbc-btn-group:last-child {
        button:last-child {
          display: none;
        }
      }
    }

    .rbc-timeslot-group {
      min-height: 70px;
    }
  }

  .rbc-today {
    background-color: unset;
  }

  .rbc-header {
    white-space: unset;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rbc-time-view, .rbc-time-header.rbc-overflowing {
    border: unset;
  }

  .rbc-time-view .rbc-row {
    min-height: auto;
  }

  .rbc-current-time-indicator {
    background-color: #FF4D4F;
    height: 2px;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #FF4D4F;
      transform: translate(-50%, -50%);
    }
  }

  .rbc-event {
    // the event should not be smaller than 15 minutes 
    min-height: 17.25px;
    padding: 0;
    padding-left: 2px;
    margin: 0;
  }

  .rbc-event.rbc-selected {
    z-index: 2;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-event-content {
    line-height: normal;
  }

  .rbc-month-header {
    .rbc-header .day {
      display: none;
    }
  }

  .rbc-off-range-bg {
    background-color: #F1F1F1;
  }
}
