.all-patient-for-ca {
  border-radius: 8px;
  border: 2px solid #1890ff;
  background: var(--Grayscale-5, #F4F6F8);
  width: 390px;
  height: 132px;

  .check-mark-ca {
    color: gray;
  }
  
  .all-patients-inner-checked-items-ca {
    margin-top: 6px;
  }
}

.patient-title-ca {
  color: var(--Grayscale-1, #2D3238);
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .ant-radio-wrapper {
    margin: 0px 0px;
  }
}