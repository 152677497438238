.BGTooltipComponent {
  font-size: 11px;
  font-weight: 600;
  line-height: normal;

  &__normal {
    margin-left: 82px;
  }

  &__dm2 {
    margin-left: 88px;
  }
}
