.order-checkbox {
  width: 100%;

  &.ant-checkbox-wrapper {
    margin-left: 0px;
  }
  
  &.ant-checkbox-wrapper> span.ant-checkbox {
    margin-right: 14px;
  }

  &.ant-checkbox-wrapper > span:not(.ant-checkbox) {
    width: 100%;
  }
}