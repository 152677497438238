.calendar-dropdown-component {
  display: flex;
  align-items: center;
}

.calendar-dropdown-overlay {
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
    background-color: white;
  }
}
