.CgmVitalSummaryComponent {
  display: flex;
  margin-top: 10px;
  margin-bottom: 15px;
  gap: 10px;
  flex-wrap: wrap;

  .tag {
    padding: 0px;
    display: inline-grid;
  }

  .solid-dot-small {
    width: 5px;
    height: 5px;
  }

  .solid-dot {
    margin-right: 0px;
  }

  // TAR level 2: Very High
  .solid-dot-error {
    background-color: #CE7A35;
  }

  // TAR level 1: High
  .solid-dot-warning {
    background-color: #DDA73B;
  }

  // TBR level 1: Low
  .solid-dot-processing {
    background-color: #BA282E;
  }

  // TBR level 2: Very Low
  .solid-dot-default {
    background-color: #742F32;
  }

  .CgmStatisticsComponent, .CgmBarChartComponent {
    min-width: 530px;
    flex: 1;
    justify-content: space-between;
  }

  &__legend {
    color: var(--Grayscale-2, #6B7178);
    font-family: "Noto Sans";
    font-size: 14px;
    margin-right: 5px;
    width: 120px;
  }

  &__label {
    color: var(--Grayscale-2, #6B7178);
    text-align: center;
    font-size: 12px;
    width: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
  }  

  &__bar-chart {
    display: grid;
    gap: max(1px);
    margin-right: 10px;
    max-height: 110px;
  }

  &__tar-level-2-bar{
    background-color: #CE7A35;
    width: 60px;
  }

  &__tar-level-1-bar{
    background-color: #DDA73B;
    width: 60px;
  }

  &__tir-bar{
    background-color: #53A15B;
    width: 60px;
  }

  &__tbr-level-1-bar{
    background-color: #BA282E;
    width: 60px;
  }

  &__tbr-level-2-bar{
    background-color: #742F32;
    width: 60px;
  }
}
