.mealImg {
  height: 250px;
  width: 250px;
}

.translation-link {
  .ant-btn {
    padding: 0;
  }
}
