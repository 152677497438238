.card-component {
  border-radius: var(--default-radius);
  margin: 0 2px;
  padding: 25px;

  &.hovered {
    box-shadow: 0px 0px 6px 0px #091E422E;
  }

  &.gray1 {
    background-color: white;
  }
  &.gray2 {
    background-color: white;
  }
  &.gray3 {
    background-color: white;
  }
  &.gray4 {
    background-color: white;
  }
  &.gray5 {
    background-color: #F4F6F8;
  }
  &.white {
    background-color: white;
  }
}
