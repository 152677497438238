.insurance-card {
  .patient-common-card-component {
    padding-left: 24px;
  }

  &.insurance-card__edit .patient-common-card-component {
    background-color: var(--gray-scale-7);
    border-width: 0px;
  }

  .insurance-card__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    
    .insurance-card__options {
      flex: 0;
    }
  }

  .insurance-card__options {
    visibility: hidden;

    button.ant-btn {
      border: none !important;
      box-shadow: none !important;
    }
  }

  &:hover .insurance-card__options {
    visibility: inherit;
  }
}