.radio-button-component {
  border-radius: var(--default-radius);
  background-color: white;
  border-color: #c7cace;
  color: var(--gray);

  &.active-btn {
    background-color: var(--uc-blue-secondary);
    border-color: var(--uc-blue);
  }
}
