.behavior-goal-select-dropdown-tabs {
  width: 100%;
  height: 360px;

  .ant-tabs-nav {
    width: 35%;
  }

  .ant-tabs-tab {
    margin-top: 0px !important;
    border-top: 1px solid var(--border-gray);
    white-space: break-spaces;
    text-align: left !important;
  }

  .ant-tabs-tab:first-of-type {
    border-top: none;
  }

  .ant-tabs-content-holder {
    overflow: auto;
    width: 100%;
    margin-bottom: 20px;
  }

  .ant-checkbox-group .ant-checkbox-wrapper {
    padding: 10px 0px;
  }

  .ant-checkbox-wrapper {
    width: 100%;
  }

  .ant-checkbox-wrapper:hover {
    background-color: rgba(24, 144, 255, 0.1);
  }
}