.card-text-component {
  &.block {
    display: block;
  }

  &.inline-block {
    display: inline-block;
  }

  &.card-text-default {
    font-size: 14px;
  }

  &.card-text-medium {
    font-size: 13px;
  }

  &.card-text-small {
    font-size: 12px;
  }

  &.card-text-large {
    font-size: 16px;
  }

  &.card-text-x-large {
    font-size: 18px;
  }

  &.card-text-xx-large {
    font-size: 24px;
  }

  &.gray1 {
    color: var(--gray-scale-1);
  }

  &.gray2 {
    color: var(--gray-scale-2);
  }

  &.gray3 {
    color: var(--gray-scale-3);
  }

  &.white {
    color: #FFF;
  }
}