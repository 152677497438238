.confirmation-modal {
  width: var(--confirmation-modal-width, 100%);
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal {
    top: 0;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .confirmation-modal-buttons button{
    padding: 0;
  }
}
