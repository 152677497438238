.password-input-form {
  margin-top: 42px;
}

.password-input-form__requirements {
  margin-top: 32px;
  margin-bottom: 40px;
  padding: 0;
  grid-column-gap: 2.5em;
  -webkit-column-gap: 2.5em;
  column-gap: 2.5em;
  display: grid;
  grid-template-columns: 50% 50%;

  .ant-form-item {
    margin-bottom: 0px;
  }
  
  .solid-dot-default {
    margin-left: 3px;
    margin-right: 18px;
  }
}
