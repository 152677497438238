.draggable-modal {
  &.ant-modal-wrap {
    pointer-events: none;
  }

  .ant-modal-mask {
    background: transparent;
    pointer-events: none;
  }

  .ant-modal {
    pointer-events: none;
  }

  .ant-modal-header {
    padding: 12px 24px;
    border-bottom-width: 2px;
  }
}

.draggable-modal-handle {
  width: 100%;
  min-height: 2em;
}

.draggable-modal .resizable {
  .ant-modal-content {
    width: 100%;
    height: -webkit-fill-available;
    min-height: 100%;
    max-height: 97vh; // give some space for handles
    display: flex;
    flex-direction: column;
  }

  .ant-modal-header {
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 1;
  }

  .ant-modal-body {
    flex: 1;
    z-index: 0;
  }
}