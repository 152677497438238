.title-with-content {
  .text-title {
    color: #6B7178;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-height: 14px;
    margin-bottom: 10px;
  }

  .text-content {
    color: #2D3238;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
