.onboarding-visit-status-status-badge {
  .ant-badge-status-dot {
    background-color: #DA6453;
    width: 8px;
    height: 8px;
  }

  .clock-icon img {
    vertical-align: text-top;
  }
}