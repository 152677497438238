.patient-common-card-component {
  align-items: baseline;
  border: 1px solid #E7EAED;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 15px;
  padding-bottom: 32px;
}

.patient-common-card-last-time-update {
  display: flex;
  align-items: center;
  .patient-common-card-edit {
    margin-right: 5px;
    color: #8C8C8C;
    .base-svg-icon {
      margin-bottom: 4px;
    }
  }
  .last-time-update {
    .tag.tag-warning {
      background: #8C8C8C;
      color: #8C8C8C;
    }
  }
}