.message-food-log-review {
  cursor: pointer;
  background: #FFFFFF;

  .ant-image,
  img {
    width: 100%;
    max-height: 200px;
    margin-bottom: 8px;
  }
}