.message-channel-section-hr-component {
  --divider-spacing: 12px;

  .ant-divider::before {
    margin-right: var(--divider-spacing);
  }

  .ant-divider::after {
    margin-left: var(--divider-spacing);
  }

  .ant-divider-inner-text {
    padding: 0 4px;
  }

  .link-style {
    color: #3177c9;
  }
}

.message-channel-section-hr-component.no-divider {
  margin: 24px 0px;
}

.message-channel-section-hr-component.no-divider,
.message-channel-section-hr-component .ant-divider-inner-text {
  font-size: 13.5px;
  font-weight: 400;
  color: var(--gray-scale-2);
}