$green: #00C8BC;
$yellow: #EF973B;
$red: var(--secondary-red);

.logbookCell {
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 7px;
  border-radius: 3px;

  &.green {
    background-color: $green;
  }
  &.yellow {
    background-color: $yellow;
  }
  &.red {
    background-color: $red;
  }

  &.reverseColor {
    background-color: white;
    font-weight: bold;

    &.green {
      border: 1px solid $green;
      color: $green;
    }
    &.yellow {
      border: 1px solid $yellow;
      color: $yellow;
    }
    &.red {
      border: 1px solid $red;
      color: $red;
    }
  }
}

.logbookCellGroup {
  color: white;
  position: relative;
  height: 100%;
  border: 1px solid white;

  .count {
    position: absolute;
    top: 0px;
    left: 2px;
  }
}

.logbookCell, .logbookCellGroup {
  .noteTag {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-left: unset;
    border-top: 28px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 28px solid;
    opacity: 0.5;
  }

  .topRightIcon {
    position: absolute;
    top: 0px;
    right: 3px;
    font-size: 10px;
    font-weight: 600;
  }

  .bottomLeftIcon {
    position: absolute;
    bottom: 0px;
    left: 3px;
  }

  .bottomRightIcon {
    position: absolute;
    bottom: 0;
    right: 3px;
  }
}

.logbookCell.reverse {
  .noteTag {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-left: unset;
    border-top: 20px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 20px solid;
    opacity: 0.5;
  }

  &.green .noteTag {
    background-color: $green;
  }
  &.yellow .noteTag{
    background-color: $yellow;
  }
  &.red .noteTag{
    background-color: $red;
  }
}
