.warning-component {
  svg {
    color: var(--secondary-red);
  }

  &.small {
    font-size: 12px;
  }
  &.medium {
    font-size: 14px;
  }
  &.large {
    font-size: 16px;
  }

  .text-danger {
    color: var(--secondary-red);
  }

  .text-alert {
    color: var(--secondary-orange);
  }

  .close-icon {
    justify-content: right;
    svg {
      color: var(--secondary-gray);
    }
  }
}
