.login-input-form-component {
  input.ant-input {
    padding: 8px;
    border-radius: 4px;
    height: 50px;
  }

  .ant-input-affix-wrapper>.ant-input:not(textarea) {
    padding-left: 12px;
  }

  .ant-input-affix-wrapper .base-icon-container,
  .ant-input-password-icon img {
    opacity: 0.7;
  }

  .ant-input-password-icon img {
    width: 20px;
    height: 20px;
  }

  .ant-btn-primary {
    border-radius: 5px;
    height: 46px;
    font-weight: 600;
    word-spacing: 1px;
  }

  .username-form-item {
    .ant-input-affix-wrapper {
      padding-right: 42px;
    }
  }

  .password-form-item {
    .ant-input-suffix {
      margin-left: 11px;
    }
  }
}