.calendar-notification-component {
  background-color: #F4F6F8;

  .ant-notification-notice-icon {
    line-height: 18px;
  }

  .ant-notification-notice-message {
    margin-left: 28px;
    font-size: 12px;
    color: #6B7178;
    font-weight: 500;
  }

  .ant-notification-notice-description {
    margin-left: 5px;
  }
}