.a1c-status-component {
  display: flex;
  align-items: center;
  gap: 7px;
  
  &.UNCONTROLLED {
    color: #C74431;
  }
  &.DIABETES {
    color: #DF7869;
  }
  &.PRE_DIABETES {
    color: #F1A454;
  }
  &.NORMAL {
    color: #00C8BC;
  }
}
