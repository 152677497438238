// standard process tag
.progress-bar {
  width: 50px;
  margin: 0 5px;
}

.current-number {
  font-weight: bold;
  font-size: 13px;
}

.progress-tag {
  color: var(--gray-scale-1);
}

.arrowUpOutlined {
  svg {
    font-size: 12px !important;
  }
}
