.outstanding-mtpr {
  --horizontal-padding: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .outstanding-mtpr-header {
    flex: 1;
    padding: 16px var(--horizontal-padding) 5px;
  }

  form#clinicalGoalForm {
    padding: 5px var(--horizontal-padding);
    margin-bottom: 18px;

    .mtpr-measurement-summary {
      width: inherit;
    }
  }

  .mtpr-measurement-summary {
    gap: 0px 12px;
    padding-right: 0px;

    &>div {
      max-width: 25%;
    }

    .card-text-default {
      font-size: 13px;
    }

    .card-text-large {
      font-size: 14px;
    }
  }

  form#mtprNote {
    position: sticky;
    bottom: 0px;
    background-color: #FFF;
    padding: 12px var(--horizontal-padding);
    border-top: 1px solid var(--border-gray);
    box-shadow: 0px -5px 8px 0px #00000008;

    label {
      // label for note input
      display: none;
    }
  }

  .mtpr-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: max-content;
    min-height: 100%;
  }

  .mtpr-form-divider {
    border: none;
    margin: 0px;
  }

  .mtpr-form-fixed-bottom {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: white;
  }

  .intervention-goal-form-component {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 5px;
  }

  .goal-form-description,
  .goal-form-status {
    width: 100%;
  }
}