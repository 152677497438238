.print-health-conditions {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 20px;
}

.print-health-conditions__item {
  display: flex;
  flex-basis: 47%;

  span:first-child {
    min-width: 50px;
  }

  span {
    margin-bottom: 6px;
  }
}