.transcribe-extendable-drawer {
  .transcribe-drawer-card {
    margin: 0px -12px;
  }

  > .ant-drawer-content-wrapper .ant-drawer-content-wrapper {
    border-right: 0.5px solid var(--bg-light-gray);
  }

  .ant-drawer-header {
    border-bottom: unset;
    box-shadow: 0px 3px var(--background-gray);
    z-index: 1;
  }

  // .ant-drawer-header-title {
  //   justify-content: flex-end;
  // }

  .ant-drawer-close {
    color: #000000;
    opacity: 0.5;
    margin-right: 0px;
  }

  >.ant-card-body {
    padding: 24px 12px;
  }

  .ant-form-item-label label {
    font-size: 12px;
  }

  .ant-form-item:not(.required-field) {
    padding-top: 3.5px;
  }

  .card-title-component {
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  #medicationFormBody {
    padding: 0px;
    padding-bottom: 16px;
  }

  .medicationsTable.border {
    border: none;
  }

  .ant-table {
    border: 0.5px solid var(--border-gray);
  }
}