.dropdown-component {
  &.ant-dropdown-button {
    .ant-btn {
      width: auto;
      height: auto;
      padding: 0 3px;
      border: unset;
      background-color: transparent;
      display: flex;
      align-items: center;
    }

    .ant-btn:first-of-type {
      display: none;
    }
    &.ant-space-compact.ant-space-compact-block  {
      justify-content: flex-end;
    }
  }
  .tree-action-icon{
    padding: 0 6px;
  }
}