.welcome-popup-modal-component {
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0px 0px;
  }

  .ant-modal-body {
    padding: 24px 170px;
    font-size: 16px;
    text-align: center;
    color: var(--text-gray-primary);
  }

  .ant-modal-footer {
    border-top: transparent;
    align-items: center;
  }

  .render-image {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .render-title {
    font-size: 32px;
    font-weight: 700;
    color: black;
  }

}

.render-get-started {
  margin-top: 24px;
  margin-bottom: 8px;

  .ant-btn {
    background-color: var(--ant-blue);
    color: white;
    border: transparent;
  }
}