.llm-lab-result-table {
  border: 1px solid var(--border-gray);
  border-radius: var(--default-radius);

  .ant-table-cell.ant-table-selection-column {
    padding-left: 12px;
  }

  // this is to fix dynamic height when error message is shown
  &.llm-lab-result-a1c-table,
  &.llm-lab-result-others-table {
    .ant-table-cell .ant-form-item {
      height: 100%;
      // 58px is the height of the input field with error message
      // put 42px to reduce padding-bottom
      min-height: 42px;
      margin-bottom: 0px;
    }

    .ant-table-cell.ant-table-selection-column {
      padding-top: 17px;
      vertical-align: top;
    }

    .ant-table-cell {
      vertical-align: baseline;
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }

    .ant-form-item.ant-form-item-with-help {
      margin-bottom: 0px;
    }

    .ant-form-item-margin-offset {
      display: none;
    }
  }

  &.llm-lab-result-others-table {
    .ant-table-cell {
      vertical-align: baseline;
    }
  }

  .ant-table-footer {
    padding: 0px;
  }

  .ant-table-placeholder {
    .ant-table-cell {
      padding: 0px;
    }
  }
}

.llm-lab-result-table--collapse {
  border-radius: var(--default-radius);
  border: unset;

  .ant-collapse-header {
    background: #FFFFFF;
    padding-top: 18px !important;
    padding-bottom: 18px !important;

    .card-text-component {
      display: inline-flex !important;
      gap: 0px 8px;
      align-items: center;
    }

    .tooltip-component {
      gap: 0px !important;
    }
  }

  .ant-collapse-item {
    border: unset;
  }

  .ant-collapse-content-box {
    padding: 0px;
  }

  .llm-lab-result-table--duplicates {
    margin-bottom: 0px;

    .ant-table-cell,
    .ant-table-cell-row-hover {
      background: var(--gray-scale-6) !important;
    }
  }
}